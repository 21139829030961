import React, { useContext, useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
    Select,
    message,
    Modal,
    Input,
    Button,
    Row,
    Col,
    Radio
} from 'antd'
import './index.less'
import propTypes from 'prop-types'
import {
    AdminUser,
} from './types'
import {
    getAdminUsers,
    sendSmsOtp,
    getAccountDetails,
    sendNotification,
    checkTxnApprovalStatus
} from '../../services/api'
import {
    /**
     * 22/1/2022 daniel.kwok
     * Hard coded HQ branch id to be 0
     * Ideally value should be from api
     */
    HQ_BRANCH,
    APP_CONST
} from '../../constants'

import { SessionContext } from '../../App'
import { getAuthorizedNric, getUserRole } from '../../services/local'


function JPJSubmissionModal(props) {

    const { user, company } = useContext(SessionContext)

    const [authCode, setAuthCode] = useState('')
    const [approverIc, setApproverIc] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [adminUsers, setAdminUsers] = useState<AdminUser[]>([])
    const [role, setRole] = useState(null)
    const [userMobileNo, setUserMobileNo] = useState('')
    const [smsProvider, setSmsProvider] = useState(1)
    const [isDisabled, setIsDisabled] = useState(false)

    const [minutes, setMinutes] = useState('00')
    const [seconds, setSeconds] = useState('00')
    const [otpTimeout, setOtpTimeout] = useState(0)

    const Ref = useRef<any>(null);

    // const params = useParams<{ transferId: string }>();
    // const { transferId } = params;
    const transferId = props.transferId;
    const [approverEmail, setApproverEmail] = useState('')
    const [showOtpSection, setShowOtpSection] = useState(true)
    const [otpSend, setOtpSend] = useState(false)
    const [pushNotificationSend, setPushNotificationSend] = useState(false)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse('' + new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setMinutes('' + (minutes > 9 ? minutes : '0' + minutes));
            setSeconds('' + (seconds > 9 ? seconds : '0' + seconds));
        } else {
            if (otpTimeout !== 0) {
                setOtpTimeout(0);
            }
            // Set OtpSend to false when countdown ends
            if (otpSend === true) {

                setOtpSend(false);
                setAuthCode('');
            }

            if (pushNotificationSend === true) {

                setPushNotificationSend(false)
                clearInterval(Ref.current)
            }
        }
    }

    const clearTimer = (e) => {

        setMinutes('0' + otpTimeout)
        setSeconds('00')

        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        // deadline.setSeconds(deadline.getSeconds() + otpTimeout)
        deadline.setMinutes(deadline.getMinutes() + otpTimeout)
        return deadline;
    }

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const sendOtp = () => {

        sendSmsOtp(approverIc, smsProvider).then(res => {
            if (!res.status) throw res.message
            // if (!res.status === true) throw res.message

            if (res.status === true) message.success(res.message)

        }).catch(err => err && message.error(err.toString()))
    }

    const resetOtpModal = () => {
        setOtpTimeout(0)
        setApproverIc(undefined)
        setUserMobileNo('')
        setAuthCode('')
        setApproverEmail('')
    }

    useEffect(() => {
        onClickReset()
    }, [otpTimeout])

    useEffect(() => {
        if(isDisabled && props.isIncorrectOtp && props.isFromPending) {
            // resetOtpModal()
        }
        if (isDisabled && props.isIncorrectOtp) {
            setIsDisabled(false)
        }
    }, [props.isIncorrectOtp, props.isModalVisible])

    useEffect(() => {
        if (props.isModalVisible) {
            getAdminUsersFromApi()
        }

        const userRole = async () => {
            await getUserRole().then(async localRole => {

                setRole(localRole)
                // if (localRole !== 'VALUER') {
                //     setApproverIc(user.nric)
                //     setUserMobileNo(user?.mobileno)
                // }
            })
        };

        userRole();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isModalVisible, showOtpSection])

    const setApproverInfo = (approverInfo) => {
        setApproverIc(approverInfo.approverIc)
        setApproverEmail(approverInfo.approverEmail)
        getAccountDetails(approverInfo.approverIc).then(res => {
            setUserMobileNo(res?.mobileno)
        }).catch(err => err && message.error(err))
    }

    function getAdminUsersFromApi() {

        setIsLoading(true)
        /**
         * 22/1/2022 daniel.kwok
         * Instructed by BE to get admin users where branchid = 0 (i.e. HQ)
         * at this moment. 
         */
        getAdminUsers(company.id, HQ_BRANCH.id)
            .then(res => {
                if (res.status !== true) throw res.message
                let userList = res.users.sort((a, b) => a.name.localeCompare(b.name))
                if (!showOtpSection) userList = userList.filter(item => item.isDeviceRegistered === 'Y');
                setAdminUsers(userList)
            })
            .catch(err => err && message.error(err.toString()))
            .finally(() => setIsLoading(false))
    }
    // useEffect(() => {

    //     if (props?.transactionType && role) {

    //         if (props?.transactionType == OUT_TRANSACTION_TYPES_IDS.D2I) {

    //             if (role == 'OWNER' || role == 'GENERAL_MANAGER') setShowApprovalSection(false)
    //             else setShowApprovalSection(true)

    //         } else {

    //             if (role == 'VALUER') setShowApprovalSection(true)
    //             else setShowApprovalSection(true)

    //         }

    //     }

    // }, [props.transactionType, role])

    const handleRadioChange = (e) => {
        setShowOtpSection(e.target.value === 'sms'); // Set to true for SMS, false for app
        if(!props.isFromPending) {
        resetOtpModal()
        }
    };

    const intervalId = useRef<NodeJS.Timer | null>(null);

    useEffect(() => {
        if (pushNotificationSend) {
            // Call sendNotification here and handle the response
            let transferIdList = props.transferIdList ? props.transferIdList : [];
            // let checkTxnTransferId = props.transferIdList ? transferIdList[0] : transferId;
            sendNotification(approverEmail, 'IN', transferId, transferIdList,0)
                .then(res => {
                    if (!res.status) {
                        message.warning(res.message)
                        setPushNotificationSend(false)
                    }
                    else {
                        let checkTxnTransferId = res.bulkTransferId != null ? res.bulkTransferId : transferId;
                        let checkTxnModuleType = res.bulkTransferId != null ? 'BULKM2M' : 'IN';
                        setOtpTimeout(3);
                        message.success(res.message)
                        setPushNotificationSend(true)
                        intervalId.current = setInterval(async () => {
                            // Call checkTxnApprovalStatus here and handle the response
                            const result = await checkTxnApprovalStatus(checkTxnTransferId, checkTxnModuleType);
                                if (result.status || result.isRejected) {
                                    result.isRejected ? message.error(result.message) : message.success(result.message)
                                    // clearInterval(intervalId.current as any);
                                    // onClickReset();
                                    setOtpTimeout(0);
                                    setPushNotificationSend(false);
                                    props.onOk(approverIc, '', true, result);//send empty authcode/otp due to use app approval
                                }
                                else if (result.errorMessage !== "") {
                                    message.error(res.errorMessage)
                                    // clearInterval(intervalId.current as any);
                                    // onClickReset();
                                    setOtpTimeout(0);
                                    setPushNotificationSend(false);
                                }
                        }, 3000);
                    }
                });
        } else if (intervalId.current) {
            clearInterval(intervalId.current as any);
        }
        //prevent interval from continuing when user navigates away from the page
        return () => clearInterval(intervalId.current as any);
    }, [pushNotificationSend]);

    const onSetApproval = async (approverIc) => {
        getAdminUsersFromApi()
        const selectedUser = adminUsers.find(u => u.nric_no === approverIc);
        // Set the approverId and name
        if (selectedUser) {
            setApproverInfo({
                approverIc: selectedUser.nric_no,
                approverEmail: selectedUser.login_name
            });
        }
    }

    useEffect(() => {
        if (props.isSelfApproval) {
            onSetApproval(getAuthorizedNric())
        }
    }, [props.isModalVisible, props.isSelfApproval, props.isFromPending])

    return (
        <>
            {
                APP_CONST.USE_OTP === true ?
                    <>
                        <Modal
                            className='approver-modal'
                            visible={props.isModalVisible}
                            okButtonProps={{
                                disabled: !authCode,
                            }}
                            onCancel={props.onCancel}
                            width={400}
                            footer={null}
                            afterClose={() => {
                                setSmsProvider(1);
                            }}
                            maskClosable={false} // This line disables closing the modal by clicking outside of it
                        >

                            <div style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>
                                    {props.isM2M
                                        ? props.isApprover
                                            ? 'Approve'
                                            : 'Submit for Seller Approval'
                                        : 'Proceed Payment'}
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 20,
                                        marginBottom: 16,
                                    }}
                                >
                                    <div>
                                        <p style={{ fontStyle: 'italic' }}>Select Approval Method</p>
                                        <Radio.Group onChange={handleRadioChange} value={showOtpSection ? 'sms' : 'app'}>
                                            <Radio value='sms' disabled={pushNotificationSend}>SMS OTP</Radio>
                                            {!props.isDisableApp && <Radio value='app' disabled={otpSend}>Mobile App</Radio>}
                                        </Radio.Group>
                                    </div>
                                    <div>
                                        <p style={{ fontStyle: 'italic' }}>Approval Name</p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 10,
                                            }}
                                        >
                                            <Select
                                                disabled={pushNotificationSend || props.isSelfApproval}
                                                // disabled={pushNotificationSend}
                                                className= { props.isFromPending ? 
                                                    'textbox-form-style': 'textbox-form-style modal-dropdown'}
                                                // className='textbox-form-style'
                                                loading={isLoading}
                                                style={{ width: '100%' }}
                                                placeholder='Select Approval Name'
                                                value={approverIc}
                                                onChange={(approverIc) => {
                                                    onSetApproval(approverIc)
                                                }}
                                            >
                                                {adminUsers.map((u) => (
                                                    <Select.Option value={u.nric_no} key={u.nric_no}>
                                                        {u.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                        </div>
                                    </div>
                                    {showOtpSection ? (
                                        <div>
                                            <p style={{ fontStyle: 'italic' }}>Verification Code</p>
                                            <p>
                                                Please enter the verification code sent to <br />
                                                <b>{userMobileNo}</b>
                                            </p>

                                            <Row gutter={[8, 8]}>
                                                <Col span={16}>
                                                    <Input.Password
                                                        className='textbox-form-style'
                                                        disabled={!approverIc || !otpSend}
                                                        placeholder='e.g. 000000'
                                                        value={authCode}
                                                        onChange={(e) => {
                                                            setAuthCode(e.target.value);
                                                        }}
                                                        visibilityToggle
                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    {minutes === '00' && seconds === '00' && (
                                                        <Button
                                                            disabled={!approverIc}
                                                            type='text'
                                                            style={{
                                                                width: '100%',
                                                                background: 'white',
                                                                border: 'none',
                                                                color: '#2B61C4',
                                                                textTransform: 'uppercase',
                                                            }}
                                                            onClick={() => {
                                                                setSmsProvider(1);
                                                                setOtpTimeout(3);
                                                                sendOtp();
                                                                setOtpSend(true)
                                                            }}
                                                        >
                                                            SEND CODE
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            {minutes === '00' && seconds === '00' ? (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                            ) : (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                    Code will expire in {minutes} min {seconds} sec
                                                </p>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {minutes === '00' && seconds === '00' ? (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}></p>
                                            ) : (
                                                <p style={{ color: 'grey', fontStyle: 'italic', marginTop: '1em' }}>
                                                    App approval will expire in {minutes} min {seconds} sec
                                                </p>
                                            )}
                                            <Button
                                                type='primary'
                                                shape={'round'}
                                                disabled={!approverEmail}
                                                onClick={() => {
                                                    setPushNotificationSend(true)
                                                }}
                                                size='large'
                                                loading={pushNotificationSend} // This line makes the button show a loading indicator
                                                style={{
                                                    width: '100%',
                                                    background: '#2B61C4 0% 0% no-repeat padding-box',
                                                    boxShadow: '0px 4px 0px #1E4489',
                                                    borderRadius: '8px',
                                                    borderColor: 'transparent',
                                                    color: 'white',
                                                }}
                                            >
                                                {pushNotificationSend ? "Waiting for approval" : "Send Push Notification Approval"}
                                            </Button>
                                        </div>
                                    )}
                                    {showOtpSection && (
                                        <Button
                                            type='primary'
                                            shape={'round'}
                                            onClick={() => {
                                                setIsDisabled(true)
                                                props.onOk(approverIc, authCode, false);
                                            }}
                                            disabled={(!authCode || !approverIc || isDisabled) && !props.isIncorrectOtp}
                                            size='large'
                                            loading={isDisabled && !props.isIncorrectOtp}
                                            style={{
                                                width: '100%',
                                                background: '#2B61C4 0% 0% no-repeat padding-box',
                                                boxShadow: '0px 4px 0px #1E4489',
                                                borderRadius: '8px',
                                                borderColor: 'transparent',
                                                color: 'white',
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Modal>
                    </>
                    :
                    <>
                        <Modal
                            className='approver-modal'
                            visible={props.isModalVisible}
                            okButtonProps={{
                                disabled: !authCode,
                            }}
                            onCancel={props.onCancel}
                            width={330}
                            footer={null}
                        >

                            <div style={{ marginTop: 24, width: '100%' }}>
                                <p style={{ textAlign: 'center', font: 'normal normal 600 24px Raleway' }}>
                                    {
                                        props.isM2M
                                            ? (props.isApprover ? 'Approve' : 'Submit for Seller Approval')
                                            : 'Proceed Payment'
                                    }
                                </p>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 20,
                                        marginBottom: 16
                                    }}
                                >
                                    {role === 'VALUER' &&
                                        <div>
                                            <p style={styles.label}>Approval Name</p>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 10
                                                }}
                                            >
                                                <Select
                                                    className='textbox-form-style modal-dropdown'
                                                    loading={isLoading}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Approval Name"
                                                    onChange={approverIc => setApproverIc(approverIc?.toString())}
                                                >
                                                    {
                                                        adminUsers.map(u => {
                                                            return <Select.Option value={u.nric_no} key={u.nric_no}>
                                                                {u.name}
                                                            </Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    }

                                    <div>
                                        <p style={styles.label}>Authentication Code</p>
                                        <Input
                                            className='textbox-form-style'
                                            disabled={props.isApprover ? false : !approverIc}
                                            placeholder='e.g. 000000'
                                            value={authCode}
                                            onChange={e => {
                                                setAuthCode(e.target.value)
                                            }}
                                        />
                                    </div>

                                    <Button
                                        onClick={() => {
                                            setIsDisabled(true)
                                            props.onOk(approverIc, authCode, false)
                                        }}
                                        disabled={!authCode || isDisabled}
                                        loading={isDisabled}
                                        type='primary'
                                        className='rectangle-button'
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Modal >
                    </>
            }
        </>

    )
}

JPJSubmissionModal.propTypes = {
    onCancel: propTypes.func,
    onOk: propTypes.func,
    isModalVisible: propTypes.bool,
    isApprover: propTypes.bool,
    isM2M: propTypes.bool,
    ownerCatagory: propTypes.string,
    transferId: propTypes.number,
    isIncorrectOtp: propTypes.bool,
    isDisableApp: propTypes.bool,
    isSelfApproval: propTypes.bool
}

JPJSubmissionModal.defaultProps = {
    isApprover: false,
    isM2M: false,
}

const styles = {
    label: {
        font: "italic normal normal 16px/19px Open Sans",
    },
    value: {
        font: 'normal normal 600 16px/19px Open Sans',
    }
}

export default JPJSubmissionModal