import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { message, Form, Modal, Spin, Button, Row, Col } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  inUpdateVehicleDetails,
  checkBulkTransactionDetails,
} from "../../services/api";
import { SessionContext } from "../../App";
import { PropsFromChildren } from ".";
import { VehicleResponse, CompanyResponse, CompanyData } from "./types";
import InventoryListingV2Page from "../../components/Inventory/InventoryV2";
import { CompanyCardComponent } from "../../components/component/Layout/CardComponent";
import {
  VehicleUpdateTableComponent,
  VehicleSelectedTableComponent,
} from "../../components/component/Layout/TableComponent";
import { TermAndConditionFormItem } from "../../components/component/Layout/FormComponent";
import {
  UpdateButton,
  BackButton,
  JPJSubmissionButton,
} from "../../components/component/UI/Button";
import { TermAndConditionCheckbox } from "../../components/component/UI/Checkbox";
import {
  saveBulkM2mBuyer,
  saveBulkM2mProceed,
  saveM2mBuyer,
  saveM2mProceed,
} from "./function";
import {
  BUTTON_JPJ_SUBMISSION,
} from "../../constants";
import { useShortcut } from "../../components/Utils";

type VehicleInformationProps = {
  // isApprover: boolean;
  company: CompanyResponse;
  vehicle: VehicleResponse;
  transferId: number;
  pendingType: string;
  isBulk: boolean;
  isReport: boolean;
  isVehicleEdit: boolean;
  isFromAdminPage: boolean;
  isJpjSubmitted: boolean;
  next: (data?: PropsFromChildren) => void;
  prev: () => void;
};

type VehicleData = {
  no: number;
  key: number;
  vehicleNo: string;
  chassisNo: string;
  engineNo: string;
  branchId: number;
};

type BulkDetails = {
  currentVehicle: string;
  totalVehicle: number;
  remainingVehicle: number;
  inquirySuccess: number;
  inquiryFailed: number;
};

export default function VehicleInformation(props: VehicleInformationProps) {
  const { company, branchs, branch, user } = useContext(SessionContext);
  const [isVehicleSearched, setIsVehicleSearched] = useState(false);
  const [vehicleDataList, setVehicleDataList] = useState<VehicleData[]>([]);
  const history = useHistory();
  const [vehicleInfoForm] = useForm();
  const [sellerCompanyData, setSellerCompanyData] = useState<CompanyData>();
  const [buyerCompanyData, setBuyerCompanyData] = useState<CompanyData>();
  const [bulkTransferId, setBulkTransferId] = useState(0);
  const [transferId, setTransferId] = useState(0);
  const [bulkDetails, setBulkDetails] = useState<BulkDetails>({
    currentVehicle: "",
    totalVehicle: 0,
    remainingVehicle: 0,
    inquirySuccess: 0,
    inquiryFailed: 0,
  });
  const [isM2MProceedProcess, setIsM2mProceedProcess] = useState(false);
  const [isM2mBulkCompleted, setIsM2mBulkCompleted] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isOneVehicle = vehicleDataList.length === 1;
  const [isDisable, setIsDisabled] = useState(false);

  const intervalId = useRef<NodeJS.Timer | null>(null);
  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };
  {
    // useShortcut(setIsModalVisible);
  }

  useEffect(() => {
    if (props.vehicle && props.vehicle.inventory) {
      const inventory: VehicleData = {
        no: 1,
        key: 1,
        vehicleNo: props.vehicle.inventory.vehicleNo,
        chassisNo: props.vehicle.inventory.chassisNo,
        engineNo: props.vehicle.inventory.engineNo,
        branchId: 0,
      };
      setVehicleDataList([{ ...inventory, key: 1 }]);
      vehicleInfoForm.setFieldsValue({
        ...vehicleInfoForm,
        chassisNo: props.vehicle.inventory.chassisNo,
        engineNo: props.vehicle.inventory.engineNo,
      });
      if (props.isJpjSubmitted && props.transferId && props.vehicle) {
        props.next({
          vehicleProps: props.vehicle,
          transferId: props.transferId,
        });
      }
    }
  }, [props.vehicle]);

  useEffect(() => {
    const sellerCompany: CompanyData = {
      id: company.id,
      coName: company.coName,
      coRegNo: company.coRegNo,
      email: company.email,
      contactNo: company.contactNo,
      ucdCode: company.ucdCode,
    };
    setSellerCompanyData(sellerCompany);

    const buyerCompany: CompanyData = {
      id: props.company?.companyDetails?.id,
      coName: props.company?.companyDetails?.coName,
      coRegNo: props.company?.companyDetails?.coRegNo,
      email: props.company?.companyDetails?.email,
      contactNo: props.company?.companyDetails?.contactNo,
      ucdCode: props.company?.companyDetails?.ucdCode,
    };
    setBuyerCompanyData(buyerCompany);
  }, [props.company]);

  /* checkBulkTransaction */
  useEffect(() => {
    if (bulkTransferId !== 0 && isModalVisible && !isM2mBulkCompleted) {
      intervalId.current = setInterval(() => {
        checkBulkTransactionDetails(bulkTransferId).then((res) => {
          if (res.status) {
            setBulkDetails(res);
          } else {
            message.error(res.message);
            clearInterval(intervalId.current as any);
            // setBulkTransferId(0);
          }

          if (res.remainingVehicle === 0) {
            message.success("Process completed.");
            clearInterval(intervalId.current as any);
            setIsM2mBulkCompleted(true);
          }
        });
      }, 3000);
    }

    return () => clearInterval(intervalId.current as any);
  }, [bulkTransferId, isModalVisible]);

  const onUpdateVehicle = () => {
    inUpdateVehicleDetails(
      vehicleInfoForm.getFieldValue("chassisNo").trim().toUpperCase(),
      company.coRegNo,
      vehicleInfoForm.getFieldValue("engineNo").trim().toUpperCase(),
      props.transferId
    )
      .then((res) => {
        if (res.updateStatus != "00") {
          if (res.updateMSG) throw res.updateMSG;

          throw res.message;
        }

        message.success(res.updateMSG);

        backToPreviousScreen();
      })
      .catch((err) => {
        err && message.error(err);
      })
      .finally(() => {});
  };

  const backToPreviousScreen = () => {
    if (isVehicleSearched) {
      onBulkModalClear();
      setIsVehicleSearched(false);
    } else {
      if (props.isBulk) {
        history.push("/reporting/transferreport/m2m/bulk");
      } else if (props.isFromAdminPage) {
        history.push("/adminSearchVehicleInfo");
      } else if (props.isReport) {
        history.push("/reporting/transferreport/");
      } else {
        history.push("/reporting/transferreport/" + props.pendingType);
      }
    }
  };

  const onJpjSubmitButtonClick = () => {
    if (bulkTransferId !== 0) {
      setIsModalVisible(true);
    }
  };

  const onResetData = () => {
    /* TODO */
  };

  const onBulkModalClear = () => {
    setBulkTransferId(0);
    setIsModalVisible(false);
    setIsM2mBulkCompleted(false);
  };

  const submissionModal = () => {
    return (
      <>
        <Modal
          visible={isModalVisible}
          // closable={isM2mBulkCompleted}
          footer={false}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          centered // This prop will center the modal
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }} // This will hide the cancel button
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <p style={{ fontSize: "2.5em" }}>
              <b>
                {isM2mBulkCompleted ? (
                  <>{bulkDetails.totalVehicle} transactions processed</>
                ) : (
                  <>
                    {bulkDetails.totalVehicle != 0
                      ? bulkDetails.totalVehicle
                      : vehicleDataList.length}{" "}
                    transactions will submit for inquiry. This may take a
                    minute.
                  </>
                )}
              </b>
            </p>
            {isM2mBulkCompleted ? (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#def2d6",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquirySuccess}</strong>
                      </span>
                      <br />
                      <span style={{ fontSize: "1.5em" }}>Successful</span>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{
                        background: "#ffefef",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <span style={{ fontSize: "2.5em" }}>
                        <strong>{bulkDetails.inquiryFailed}</strong>
                      </span>
                      <br /> <span style={{ fontSize: "1.5em" }}>Failed</span>
                    </div>
                  </Col>
                </Row>
                <br />
                <p style={{ fontSize: "1.5em" }}>
                  Retry the {bulkDetails.inquiryFailed} failed transactions from
                  the <br />
                  transaction list.
                </p>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Spin size="large" />
              </div>
            )}
            <br />
            <Button
              type="primary"
              disabled={!isM2mBulkCompleted}
              onClick={() => {
                props.next({ bulkTransferId: bulkTransferId });
              }}
            >
              Approval
            </Button>
          </div>
          {/* )} */}
        </Modal>
      </>
    );
  };

  const onFormFinal = async () => {
    if (isM2mBulkCompleted) {
      return;
    }

    if (isOneVehicle) {
      let currentTransferId = transferId;
      if (transferId === 0) {
        setIsDisabled(true);
        await saveM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList[0],
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setTransferId(resultId);
            currentTransferId = resultId;
          } else {
            setIsDisabled(false);
            return;
          }
        });
      }

      const response = await saveM2mProceed(
        currentTransferId,
        "SUBMISSION",
        onResetData
      );

      setIsM2mBulkCompleted(true);
      setIsDisabled(false);
      props.next({ transferId: currentTransferId });
    } else {
      if (isM2MProceedProcess) {
        return;
      }

      let currentBulkId = bulkTransferId;
      if (bulkTransferId === 0) {
        await saveBulkM2mBuyer(
          props.company?.companyDetails,
          vehicleDataList,
          company,
          branch,
          user
        ).then(async (resultId) => {
          if (resultId !== null) {
            setBulkTransferId(resultId);
            currentBulkId = resultId;
          } else {
            return;
          }
        });
      }

      const response = await saveBulkM2mProceed(
        setBulkTransferId,
        currentBulkId,
        [],
        company.id,
        onResetData,
        MODULE_TYPE.SUBMISSION
      );

      if (response) {
        setIsM2mProceedProcess(true);
        setIsModalVisible(true);
      }
    }
  };

  return (
    <>
      {props.isVehicleEdit ? (
        /* Vehicle Edit */
        <>
          {VehicleUpdateTableComponent(vehicleDataList, vehicleInfoForm)}
          <div className="section-bottom-button">
            {BackButton(backToPreviousScreen)}
            {UpdateButton(onUpdateVehicle)}
          </div>
        </>
      ) : !isVehicleSearched ? (
        /* Inventory Listing */
        <>
          <InventoryListingV2Page
            props={props}
            setIsVehicleSearched={setIsVehicleSearched}
            setVehicleSelectedData={setVehicleDataList}
          />
        </>
      ) : (
        /* JPJ Submission Confirmation */
        <>
          <Form onFinish={onFormFinal}>
            {CompanyCardComponent(sellerCompanyData, buyerCompanyData)}
            {VehicleSelectedTableComponent(vehicleDataList, branchs)}

            {/* Term and Condition */}
            {TermAndConditionFormItem(TermAndConditionCheckbox)}
            <br />

            {BackButton(backToPreviousScreen)}
            <Form.Item>
              {JPJSubmissionButton(onJpjSubmitButtonClick, isDisable, BUTTON_JPJ_SUBMISSION)}
            </Form.Item>
          </Form>
        </>
      )}
      {submissionModal()}
    </>
  );
}
