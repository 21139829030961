// @ts-nocheck
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Row,
    Select,
    Image,
    Typography,
    Space,
    Modal,
    Tooltip,
    Table
} from 'antd'
import moment from 'moment'
import propTypes from 'prop-types'
import
React,
{
    useEffect,
    useState,
    useContext
} from 'react'
import {
    useHistory,
    useLocation,
    useParams
} from 'react-router-dom'
import {
    SEARCH_QUERY_TOOLTIP
} from '../../constants'
import {
    getInVehicleTypes,
    getInTransferByTransferId,
    inUpdateVehicleDetails,
    getVehicleDetailsVix,
    saveInSeller,
    isAccountExist,
    getDistrictList,
    create1MIDAccount
} from '../../services/api'
import {
    GetVehicleTypesResponse,
    GetInTransferDetailResponse,
    GetInTransferDetailRequest,
    InSaveSellerRequest,
    InSaveSellerResponse
} from '../../services/api/types'
import './index.less'
import {
    HistoryState,
    STEPS
} from './types'
import arrow_back from '../../assets/arrow_back.svg'
import { links } from '../../config'
import { disabledYear, breakdownIc } from '../../util'
import { Params } from './types'
import { useForm } from 'antd/lib/form/Form'
import { SessionContext } from '../../App'
import {
    SaveOutlined,
} from '@ant-design/icons'
import {
    vehicleRegistrationNumber,
    make,
    model,
    manufactureYear,
    chassisNumber,
    engineNumber,
    vehicleType,
  } from "../../components/VehicleInformation";

function VehicleInformation(props) {

    const { company, user, branch } = useContext(SessionContext)
    const history = useHistory()
    const { state: locationState } = useLocation<HistoryState>()
    const [vehicleTypes, setVehicleTypes] = useState<GetVehicleTypesResponse>([])
    const [vehicleLoading, setVehicleLoading] = useState(true)
    const [vehicleDetailsFound, setVehicleDetailsFound] = useState(false)
    const params = useParams<Params>()
    const [vehicleInfoForm] = useForm()
    const [isOnsaveModalVisible, setIsOnsaveModalVisible] = useState(false)
    const [saveSellerLoading, setSaveSellerLoading] = useState(false)
	const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        vehicleInfoForm.setFieldsValue({
            ...props.vehicle,
            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
        })

    }, [props.vehicle])

    const handleCheckRegistrationNo = () => {

        let vehicleNumber = vehicleInfoForm.getFieldValue('vehicleRegistrationNo')
        setSearchTerm(vehicleNumber)

        if (!vehicleNumber) {
            message.warn('Vehicle Registration Number cannot be empty.')
            return
        }

        setVehicleLoading(true)

        getVehicleDetailsVix(vehicleNumber)
            .then(res => {

                if (!res.vehicleRegNo && !res.make && !res.model && !res.chassisNo && !res.engineNo && !res.manufacturerYear) {

                    setVehicleDetailsFound(false)

                    vehicleInfoForm.setFieldsValue({
                        vehicleRegistrationNo: vehicleNumber,
                        // carMake: '',
                        // carModel: '',
                        chassisNo: '',
                        engineNo: '',
                        // manufactureYear: '',
                        // vehicleType: ''
                    })

                    const newMessage = (
                        <div>
                            No vehicle details found for {vehicleNumber}.
                            <br />
                            Please fill in the details manually for further checking.
                        </div>
                    );
                    message.info(newMessage);
                    return;
                }

                let vehicle = {
                    // carMake: res.make,
                    // carModel: res.model,
                    chassisNo: res.chassisNo,
                    engineNo: res.engineNo,
                    // manufactureYear: res.manufacturerYear,
                    vehicleRegistrationNo: res.vehicleRegNo,
                    // vehicleType: res.vehicleType,
                }

                vehicleInfoForm.setFieldsValue({
                    ...vehicle,
                    manufactureYear: moment(vehicle?.manufactureYear || undefined)
                })

                setVehicleDetailsFound(true)

            })
            .catch((err) => {
                err && message.error(err)
                setVehicleDetailsFound(false)
                vehicleInfoForm.setFieldsValue({
                    vehicleRegistrationNo: vehicleNumber,
                    // carMake: '',
                    // carModel: '',
                    chassisNo: '',
                    engineNo: '',
                    // manufactureYear: '',
                    // vehicleType: ''
                })
            })
            .finally(() => { setVehicleLoading(false) })
    }

    const saveSeller = async (vehicle) => {

        try {
            if (!props?.seller?.state) {
                message.error('State is missing.')
                return
            }

            let sellerDistrictList = await getDistrictList(props?.seller?.state)

            if (!sellerDistrictList) {
                message.error('District list is empty.')
                return
            }

            /* if (!props?.seller?.district) {
                message.error('District is missing.')
                return
            }
 */
            let sellerDistrictCode = props.seller?.district ? sellerDistrictList.find(district => (
                district?.districtName.toUpperCase() === props.seller?.district.toUpperCase()
            ))?.districtCode : "";

            const inSaveSellerRequest: InSaveSellerRequest = {
                branchId: branch.id,
                buyerCorrespondentAddress1: "",
                buyerCorrespondentAddress2: "",
                buyerCorrespondentAddress3: "",
                buyerCorrespondentDistrict: "",
                buyerCorrespondentDistrict: !company.companyAddress?.district ? company.companyAddress?.city : company.companyAddress?.district,
                buyerCorrespondentEmail: "",
                buyerCorrespondentMobileNo: "",
                buyerCorrespondentPostcode: "",
                buyerCorrespondentState: "",
                currentLoginUserName: user.username,
                dealerCompanyROC: company.coRegNo,
                sellerInfo: {
                    address1: props?.seller?.address1,
                    address2: props?.seller?.address2,
                    address3: props?.seller?.address3,
                    correspondentAddress1: props?.seller?.address1,
                    correspondentAddress2: props?.seller?.address2,
                    correspondentAddress3: props?.seller?.address3,
                    correspondentDistrict: sellerDistrictCode,
                    correspondentEmail: props?.seller?.correspondentEmail,
                    correspondentMobileNo: props?.seller?.correspondentMobileNo,
                    correspondentPostcode: props?.seller?.postcode,
                    correspondentState: props?.seller?.state,
                    dateOfBirth: props?.seller?.dateOfBirth,
                    district: sellerDistrictCode,
                    city: props?.seller?.city,
                    email: props?.seller?.email,
                    gender: props?.seller?.gender,
                    identificationNo: props?.seller?.identificationNo,
                    name: props?.seller?.name,
                    phoneNo: props?.seller?.phoneNo,
                    mobileNo: props?.seller?.mobileNo,
                    postcode: props?.seller?.postcode,
                    repIc: props?.seller?.repIc,
                    repIc2: props?.seller?.repIc2,
                    repName: props?.seller?.repName,
                    sellerType: Number(vehicle.ownerIdCat),
                    state: props?.seller?.state,
                    citizenship: props?.seller?.citizenship?.replace(/\0.*$/, "").toUpperCase(),
                },
                vehicleInfo: {
                    // carMake: vehicle?.carMake,
                    // carModel: vehicle?.carModel,
                    chassisNo: vehicle?.chassisNo,
                    engineNo: vehicle?.engineNo,
                    isWhiteListVehicle: vehicle?.isWhiteListVehicle,
                    lkmoption: vehicle?.lkmoption,
                    // manufactureYear: vehicle?.manufactureYear,
                    ownerIdCat: vehicle?.ownerIdCat,
                    vehicleRegistrationNo: vehicle?.vehicleRegistrationNo,
                    // vehicleType: vehicle?.vehicleType != "" ? vehicle?.vehicleType : "1"
                }
            }

            let isAccountExistRes = await isAccountExist(inSaveSellerRequest.sellerInfo.identificationNo)

            if (isAccountExistRes.status === false) {

                const { formattedDob, isMale } = breakdownIc(inSaveSellerRequest?.sellerInfo?.identificationNo)
                let localFormattedDob = moment(new Date('' + formattedDob)).format("DD-MM-YYYY")

                let create1MIDAccountRes = await create1MIDAccount(
                    inSaveSellerRequest?.sellerInfo?.correspondentAddress1,
                    inSaveSellerRequest?.sellerInfo?.correspondentAddress2,
                    inSaveSellerRequest?.sellerInfo?.correspondentAddress3,
                    localFormattedDob,
                    '',
                    '',
                    // inSaveSellerRequest?.sellerInfo?.correspondentDistrict,
                    inSaveSellerRequest?.sellerInfo?.city,
                    inSaveSellerRequest?.sellerInfo?.correspondentEmail,
                    inSaveSellerRequest?.sellerInfo?.correspondentEmail,
                    isMale ? "MALE" : "FEMALE",
                    inSaveSellerRequest?.sellerInfo?.correspondentMobileNo,
                    inSaveSellerRequest?.sellerInfo?.name,
                    inSaveSellerRequest?.sellerInfo?.identificationNo,
                    false,
                    inSaveSellerRequest?.sellerInfo?.correspondentMobileNo,
                    inSaveSellerRequest?.sellerInfo?.correspondentPostcode,
                    '',
                    '',
                    inSaveSellerRequest?.sellerInfo?.correspondentState
                )

                if (!create1MIDAccountRes.status) {

                    let msg = create1MIDAccountRes.message

                    if ('Sorry, an account already exists with email address - ' === create1MIDAccountRes.message) {
                        msg += inSaveSellerRequest?.sellerInfo?.correspondentEmail
                    }

                    throw msg
                }

            }

            let saveInSellerRes = await saveInSeller(inSaveSellerRequest)

            if (saveInSellerRes.saveSellerStatus !== '00') throw saveInSellerRes.saveSellerMSG ?? saveInSellerRes.message

            message.success('Owner information saved.')

            return saveInSellerRes.transferId

        } catch (error) {
            error && message.error(error?.toString())
        }

    }

    const onSavePressed = async () => {

        setSaveSellerLoading(true)

        const vehicleInfo = {
            // 'carMake': vehicleInfoForm.getFieldValue('carMake').toUpperCase(),
            // 'carModel': vehicleInfoForm.getFieldValue('carModel').toUpperCase(),
            'chassisNo': vehicleInfoForm.getFieldValue('chassisNo').toUpperCase(),
            'engineNo': vehicleInfoForm.getFieldValue('engineNo').toUpperCase(),
            'manufactureYear': vehicleInfoForm.getFieldValue('manufactureYear')?.format("YYYY"),
            'vehicleRegistrationNo': vehicleInfoForm.getFieldValue('vehicleRegistrationNo').replaceAll(' ', '').toUpperCase(),
            // 'vehicleType': vehicleInfoForm.getFieldValue('vehicleType')
        }

        const vehicle = {
            ...props.vehicle,
            ...vehicleInfo
        }

        await saveSeller(vehicle)

        setSaveSellerLoading(false)
        setIsOnsaveModalVisible(false);

        message.loading('Redirect to homepage...')

        setTimeout(() => {
            window.location.pathname = '/'
        }, 1 * 1000);
    }

    const onChangeVehicleInput = (e) => {

        setSearchTerm(e.target.value);
        const newValue = e.target.value.toUpperCase().trim().replace(/\s/g, "");
        vehicleInfoForm.setFieldsValue({
            vehicleRegistrationNo: newValue
        });

        if (newValue === '') {
            setVehicleDetailsFound(false)
        }
    }

    const COMPONENT_STEPS = {
        [STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO]: {
            component: (
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'flex-start',
                        // alignItems: 'flex-start',
                        // flexDirection: 'column'
                    }}
                >
                    <Modal className='in-save-seller-info-modal' visible={isOnsaveModalVisible} onCancel={() => { setIsOnsaveModalVisible(false) }} footer={null} width={330}>
                        <Space direction='vertical' style={{ textAlign: 'center', marginTop: 24 }}>
                            <h1 style={{ font: "normal normal 600 24px Raleway" }}>Save the current information?</h1>
                            <p style={{ font: "normal normal 400 18px Raleway" }}>The current information will be save and you will be redirect to homepage when it's completed.</p>
                            <Button
                                size='large'
                                style={{

                                    background: " #2B61C4 0% 0% no-repeat padding-box",
                                    boxShadow: "0px 4px 0px #1E4489",
                                    borderRadius: "8px",
                                    color: 'white',
                                    borderColor: 'transparent'
                                }}
                                type='primary'
                                onClick={() => onSavePressed()}
                                loading={saveSellerLoading}
                            >
                                Save and back to homepage
                            </Button>
                            <Button type='link' onClick={() => { setIsOnsaveModalVisible(false) }} style={{ font: 'normal normal 600 14px Open Sans', color: "#2B61C4", paddingTop: 16 }}>
                                CANCEL
                            </Button>
                        </Space>
                    </Modal>
                    <Form
                        form={vehicleInfoForm}
                        layout={'vertical'}
                        style={{
                            width: '100%',
                        }}
                        onFinish={async (values) => {

                            setSaveSellerLoading(true)

                            // values.manufactureYear = values.manufactureYear?.format('YYYY')
                            values.vehicleRegistrationNo = values.vehicleRegistrationNo.replaceAll(' ', '').toUpperCase()
                            // values.carMake = values.carMake.trim().toUpperCase()
                            // values.carModel = values.carModel.trim().toUpperCase()
                            values.chassisNo = values.chassisNo.trim().toUpperCase()
                            values.engineNo = values.engineNo.trim().toUpperCase()

                            const vehicle = {
                                ...props.vehicle,
                                ...values
                            }

                            let transferId = await saveSeller(vehicle)

                            props.onChange(vehicle)

                            history.push(history.location.pathname, {
                                ...locationState,
                                vehicle: vehicle,
                                step: STEPS.JPJ_SUBMISSION.label,
                                subStep: STEPS.JPJ_SUBMISSION.subSteps.REVIEW,
                                transferId: transferId
                            })

                            setSaveSellerLoading(false)

                        }}
                        initialValues={{
                            ...props.vehicle,
                            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >

                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row gutter={16} style={{ width: '100%' }}>
                                {vehicleRegistrationNumber(
                                    styles,
                                    searchTerm,
                                    onChangeVehicleInput,
                                    vehicleLoading,
                                    handleCheckRegistrationNo,
                                    false)}
                            </Row>

                            {vehicleDetailsFound === true && (
                                <Typography.Text style={{ color: 'red', fontStyle: 'italic', textAlign: 'left', display: 'block' }}>
                                    The engine and chassis numbers generated by the system may not always align with the records maintained by the JPJ system. Alternatively, adjustments can be made to comply with the GRANT / VOC. If the system displays an error stating 'No Vehicle Was Found' or 'Incorrect Engine / Chassis Number', appropriate modifications can be applied accordingly.
                                </Typography.Text>
                            )}


                            <hr style={{ width: '100%', borderTop: '1px solid #D8D8D8' }} />

                            {/* <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    {make(styles, null, null, false)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {model(styles, null, null, false)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {manufactureYear(styles, null, null, disabledYear, false)}
                                </Col>
                            </Row> */}

                            <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    {chassisNumber(styles)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {engineNumber(styles)}
                                </Col>
                                {/* <Col className="gutter-row" span={8}>
                                    {vehicleType(styles, vehicleLoading, vehicleTypes, null, false)}
                                </Col> */}
                            </Row>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginBlock: 24
                            }}
                        >
                            <Form.Item
                                name='tnc'
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value
                                                ? Promise.resolve()
                                                : Promise.reject(),
                                        message: (
                                            <span>To proceed, you need to agree with the terms & conditions</span>
                                        )
                                    }
                                ]}
                            >
                                <Checkbox>
                                    <p style={{ textAlign: 'left' }}>
                                        I hereby agree to transfer the ownership of this vehicle and shall take full responsibility on this transfer upon the agreed
                                        <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={links.termsAndCondition}
                                        >
                                            &nbsp; Terms & Conditions.
                                        </a>
                                    </p>
                                </Checkbox>
                            </Form.Item>
                        </div>


                        <Form.Item>

                            <Row justify='space-between'>
                                <Col>
                                    <Button className='back-button buttons'
                                        shape='round'
                                        onClick={() => {
                                            history.push(history.location.pathname, {
                                                ...locationState,
                                                step: STEPS.AUTHORIZE_SELLER.label,
                                                subStep: STEPS.AUTHORIZE_SELLER.subSteps.SUMMARY
                                            })
                                        }}
                                    > <Image src={arrow_back} preview={false} />
                                        Back
                                    </Button>
                                </Col>
                                <Space style={{ alignItems: 'self-start' }}>
                                    <Button
                                        className='back-button buttons'
                                        icon={<SaveOutlined />}
                                        shape='round'
                                        onClick={() => {
                                            setSearchTerm(vehicleInfoForm.getFieldValue('vehicleRegistrationNo'))
                                            vehicleInfoForm
                                                .validateFields()
                                                .then(() => {
                                                    setIsOnsaveModalVisible(true);
                                                });
                                        }}
                                        loading={saveSellerLoading}
                                    >
                                        Save
                                    </Button>
                                    <Button size='large'
                                        className='rectangle-button'
                                        htmlType='submit'
                                        type='primary'
                                        loading={saveSellerLoading}
                                        onClick={() => {
                                            setSearchTerm(vehicleInfoForm.getFieldValue('vehicleRegistrationNo'))
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Space>
                            </Row>
                        </Form.Item>
                    </Form>
                </div>
            )
        },
        [STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO]: {
            component: (
                <div>
                    <Form
                        form={vehicleInfoForm}
                        layout={'vertical'}
                        style={{
                            width: '100%',
                        }}
                        onFinish={values => {

                            setVehicleLoading(true)

                            inUpdateVehicleDetails(
                                // values.carMake.trim().toUpperCase(),
                                // values.carModel.trim().toUpperCase(),
                                values.chassisNo.trim().toUpperCase(),
                                props?.dealer?.identificationNo,
                                values.engineNo.trim().toUpperCase(),
                                // values.manufactureYear?.format("YYYY"),
                                params.transferId ?? locationState?.transferId,
                                // values.vehicleType
                            ).then(res => {

                                if (res.updateStatus != '00') {

                                    if (res.updateMSG) throw res.updateMSG

                                    throw res.message
                                }

                                message.success(res.updateMSG)

                                if (locationState?.fromAdminPage == true)
                                    history.push('/adminSearchVehicleInfo')
                                // return
                                else
                                    history.push('/reporting/transferreport/In')

                            }).catch(err => { err && message.error(err) }).finally(() => setVehicleLoading(false))
                        }}
                        initialValues={{
                            ...props.vehicle,
                            manufactureYear: moment(props.vehicle?.manufactureYear || undefined)
                        }}
                    >

                        <div style={{
                            padding: '24px',
                            background: "#FFFFFF 0% 0% no-repeat padding-box",
                            boxShadow: "0px 3px 6px #00000029",
                            borderRadius: "4px",
                            width: '100%',

                        }}>
                            <h2 style={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 24 }}>Vehicle Information</h2>
                            <Row gutter={16} style={{ width: '100%', }}>
                                <Col className="gutter-row" span={8}>
                                    <Form.Item
                                        label={<p style={styles.label}>Vehicle Registration Number</p>}
                                        name='vehicleRegistrationNo'
                                        rules={[
                                            {
                                                required: true,
                                                pattern: new RegExp('^[a-zA-Z0-9]{2,20}$'),
                                                message: (
                                                    <ol>
                                                        <li>{'Must be between 2 - 20 characters'}</li>
                                                        <li>{'Allow for (a-z), (A-Z), (0-9)'}</li>
                                                    </ol>
                                                )
                                            },
                                        ]}
                                    >
                                        <Input
                                            disabled
                                            placeholder='Enter vehicle registration number'
                                            style={{ width: '100%' }} className='textbox-form-style'
                                            onChange={(e) => {
                                                vehicleInfoForm.setFieldsValue({
                                                    vehicleRegistrationNo: e.target.value.toUpperCase()
                                                })
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    {make(styles, props, history, true)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {model(styles, props, history, true)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {manufactureYear(styles, props, history, disabledYear, true)}
                                </Col>
                            </Row> */}


                            <Row gutter={16}>
                                <Col className="gutter-row" span={8}>
                                    {chassisNumber(styles)}
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    {engineNumber(styles)}
                                </Col>
                                {/* <Col className="gutter-row" span={8}>
                                    {vehicleType(styles, vehicleLoading, vehicleTypes, history, true)}
                                </Col> */}
                            </Row>
                        </div>

                        <Form.Item>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBlock: 24
                                }}
                            >
                                <Button className='back-button buttons'
                                    shape='round'
                                    onClick={() => {
                                        if (locationState?.fromAdminPage == true)
                                            history.push('/adminSearchVehicleInfo')
                                        else
                                            history.push('/reporting/transferreport/In')
                                    }}
                                > <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                                <Button
                                    loading={vehicleLoading}
                                    size='large'
                                    className='rectangle-button'
                                    htmlType='submit'
                                    type='primary'
                                >
                                    Update
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            )
        },
    }

    const [currentSubStep, setCurrentSubStep] = useState(STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO)
    const currentStep = COMPONENT_STEPS[currentSubStep]

    useEffect(() => {
        // if (!history.location.state) return null
        // if (!history.location.state.subStep) return null
        setCurrentSubStep(locationState?.subStep || '')
    }, [history.location.state])

    useEffect(() => {
        setVehicleLoading(true)
        getInVehicleTypes()
            .then(res => {
                setVehicleLoading(false)
                if (!Array.isArray(res)) throw `Unable to get vehicle types`
                setVehicleTypes(res)
            })
            .catch(err => err && message.error(err))
    }, [])

    return (

        <Card
            style={{
                height: '50%',
                width: '100%',
                maxWidth: 1200,
                background: 'transparent',
                border: 0,
                textAlign: 'left'
            }}
        >
            {
                currentStep && currentStep.component
            }
            {
                !params.transferId || !locationState?.transferId && !props.vehicle.vehicleRegistrationNo &&
                currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO && currentSubStep &&
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <h1>Unknown transfer</h1>
                    <a
                        onClick={() => locationState?.fromAdminPage == true
                            ?
                            history.push('/adminSearchVehicleInfo')
                            :
                            history.push('/reporting/transferreport/In')}
                    >Go back pending list</a>
                </Card>
            }
            {
                !params.transferId || !locationState?.transferId && !props.vehicle.vehicleRegistrationNo &&
                currentSubStep != STEPS.VEHICLE_INFORMATION.subSteps.UPDATE_VEHICLE_INFO &&
                <Card
                    style={{
                        height: '50%',
                        width: '100%',
                        boxShadow: '0px 3px 6px #00000029',
                        borderRadius: 4
                    }}
                >
                    <Table loading={true}/>
                </Card>
            }
        </Card>
    )
}

VehicleInformation.propTypes = {
    vehicle: propTypes.object,
    dealer: propTypes.object,
    onChange: propTypes.func,
}

const styles = {
    label: {
        color: 'grey', fontStyle: 'italic', marginBottom: 0,
    },
    value: {
        fontWeight: 'bold',
    },
    ownershipTypeCard: {
        cursor: 'pointer',
        margin: 5,
    },
    ownershipTypeCardDisabled: {
        opacity: 0.5,
        margin: 5
    },
    ownershipTypeCardSelected: {
        boxShadow: `0 0 20px rgba(9, 8, 8, 0.393)`,
        transition: `box-shadow .1s`,

    }
}

export default VehicleInformation