import React, { useEffect, useState, useContext } from 'react'
import {
    Form,
    Input,
    Button,
    Image,
    Select,
    Space,
    message,
    Row,
    Col,
    Checkbox
} from 'antd'
import './index.less'
import { useHistory } from 'react-router-dom'

import thumbprint from '../../assets/thumbprint.jpg'
import green_thumbprint from '../../assets/illus-thumb-success.svg'
import red_thumbprint from '../../assets/illus-thumb-fail.svg'
import arrow_back from '../../assets/arrow_back.svg'
import {
    LoadingOutlined,
} from '@ant-design/icons'
import {
    STEPS,
} from './steps'
import {
    breakdownIc,
    parseAddress,
    removeTrailings,
    getState
} from '../../util'
import {
    getDistrictList,
    isAccountExist,
    getAccountDetails,
    getCityList,
    validateCityWithRequestParam,
} from '../../services/api'
import DeviceReader from '../../components/DeviceReader'
import propTypes from 'prop-types'
import {
    STATES,
    OUT_TRANSACTION_TYPES_IDS,
    STATES_FULLNAME,
    CUSTOMER_CATEGORY
} from '../../constants'
import { useForm } from 'antd/lib/form/Form'
import {
    getCompanyId,
} from '../../services/local'
import { decryptUrlVerify } from '../../components/Utils'
import { SessionContext } from '../../App'

function AuthorizeBuyer(props) {

    const history = useHistory()
    const [form] = useForm()
    const [districtLoading, setDistrictLoading] = useState(false)
    const [districts, setDistricts] = useState([])
    const [correspondentDistricts, setCorrespondentDistricts] = useState([])
    const { formattedDob, isMale } = breakdownIc(props.buyer?.identificationNo)
    const [isAddressDifferent, setIsAddressDifferent] = useState(false)
    const [buyerCity, setBuyerCity] = useState("")
    const [cityList, setCityList] = useState([])
    const [cityLoading, setCityLoading] = useState(false)
    const [isShowCity, setIsShowCity] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const { user, } = useContext(SessionContext)

    useEffect(() => {

        if (props.buyer.isAddressDifferent)
            setIsAddressDifferent(props.buyer.isAddressDifferent)

    }, [props.buyer.isAddressDifferent])

    useEffect(() => {

        if (isAddressDifferent) {
            form.setFieldsValue({
                correspondentAddress1: props?.buyer?.correspondentAddress1 && props?.buyer?.correspondentAddress1,
                correspondentAddress2: props?.buyer?.correspondentAddress2 && props?.buyer?.correspondentAddress2,
                correspondentAddress3: props?.buyer?.correspondentAddress3 && props?.buyer?.correspondentAddress3,
                correspondentPostcode: props?.buyer?.correspondentPostcode && props?.buyer?.correspondentPostcode
            })
        } else
            form.setFieldsValue({
                correspondentAddress1: '',
                correspondentAddress2: '',
                correspondentAddress3: '',
                correspondentPostcode: ''
            })

    }, [isAddressDifferent, props.buyer])

    useEffect(() => {

        if (history.location.state.subStep)
            setSubStep(history.location.state.subStep)

    }, [history.location.state.subStep])

    useEffect(() => {

        if (props?.buyer?.state) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.state))?.code

            if (stateCode) {
                setDistrictLoading(true)
                getDistrictList(stateCode).then((res) => {
                    setDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                })
            }
        }

    }, [props.buyer.state])

    useEffect(() => {

        if (props?.buyer?.correspondentState) {

            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.correspondentState))?.code

            if (stateCode) {
                form.setFieldsValue({
                    correspondentState: stateCode,
                })
                setDistrictLoading(true)
                getDistrictList(stateCode).then((res) => {
                    setCorrespondentDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setDistrictLoading(false)
                })
            }
        }

    }, [props.buyer.correspondentState])

    useEffect(() => {
        districts
            .filter(district => props?.buyer?.district && district?.districtName?.toUpperCase() === props?.buyer?.district?.toUpperCase())
            .map(district => {
                form.setFieldsValue({
                    district: district.districtCode,
                })
            })
    }, [districts, props.buyer.district])

    useEffect(() => {
        correspondentDistricts
            .filter(district => props?.buyer?.correspondentDistrict && district?.districtName?.toUpperCase() === props?.buyer?.correspondentDistrict?.toUpperCase())
            .map(district => {
                form.setFieldsValue({
                    correspondentDistrict: district.districtCode,
                })
            })
    }, [correspondentDistricts, props.buyer.correspondentDistrict])

    useEffect(() => {

        form.setFieldsValue({
            correspondentMobileNo: !props?.buyer?.correspondentMobileNo ? props?.buyer?.mobileNo : props?.buyer?.correspondentMobileNo,
            correspondentEmail: !props?.buyer?.correspondentEmail ? props?.buyer?.email : props?.buyer?.correspondentEmail,
        })

    }, [props])

    /* 
        Validate if city is mapped in DB,
        Else get city list
    */
    useEffect(() => {
        const getCityListing = () => {
            const stateCode = Object.values(STATES).find(state => state.code.includes(props?.buyer?.state))?.code
            if (stateCode) {
                setCityLoading(true)
                getCityList(stateCode).then((res) => {
                    setCityList(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setIsShowCity(true)
                    setCityLoading(false)
                })
            }
        }
        if (props.buyer.city) {
            validateCityWithRequestParam(props.buyer.city).then((res) => {
                if(!res.status) {
                    getCityListing()
                }
                else {
                    setBuyerCity(props.buyer.city)
                }
            }).catch((err) => {
                err && message.error(err?.toString())
                getCityListing()
            }).finally(() => {
                setIsButtonDisabled(false)
            })
        }
    }, [props.buyer.city])

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.INSERTING_IC]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>retrieving data...</p>
                    <LoadingOutlined />
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_BEFORE]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUBMPRINTING]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <LoadingOutlined
                        style={{ marginBottom: 20 }}
                    />
                    <p>Place thumb on device</p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_SUCCESS]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={green_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'green' }}>
                        Verified!
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED]: {
            component: (
                <div>
                    <h1
                        style={{
                            fontSize: 25,
                            fontWeight: 'bolder'
                        }}
                    >
                        Verify buyer's identity
                    </h1>
                    <p>Thumbprint Verification</p>
                    <img alt='' src={red_thumbprint} height={100} style={{ marginBottom: 20 }} />
                    <p style={{ color: 'red' }}>
                        Failed. Try again?
                    </p>
                </div>
            )
        },
        [STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY]: {
            component: (
                <>
                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                        <Col span={12}>
                            <div style={{
                                padding: 24,
                                background: "#FFFFFF 0% 0% no-repeat padding-box",
                                boxShadow: "0px 3px 6px #00000029",
                                borderRadius: 4,
                                width: '100%',
                                marginBottom: 24,
                                height: '89.5%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <h2 style={{ fontWeight: 'bold', marginTop: 20 }}>Buyer's Identity Card Details</h2>
                                <div style={{ display: 'flex' }}>
                                    <img alt='' src={`data:image/png;base64, ${props.buyer.photo}`} height='200px' style={{ marginRight: '10%' }} />
                                </div>
                                <div style={{ display: 'flex', marginTop: '20px' }}>
                                    <div style={{ flex: 1 }}>
                                        <Space direction='vertical'>
                                            <p style={styles.label}>Name</p>
                                            <p style={styles.value}>{props.buyer.name}</p>
                                            <p style={styles.label}>NRIC</p>
                                            <p style={styles.value}>{props.buyer.identificationNo}</p>
                                            <p style={styles.label}>Date of Birth</p>
                                            <p style={styles.value}>{formattedDob}</p>
                                            <p style={styles.label}>Gender</p>
                                            <p style={styles.value}>{isMale ? 'Male' : 'Female'}</p>
                                            <p style={styles.label}>Address</p>
                                            <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer?.state)?.label, district: "" })}</p>
                                        </Space>
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: 32
                                }}
                            >
                                <Button
                                    className='back-button buttons'
                                    shape='round'
                                    onClick={() => {

                                        props.onChange({})

                                        history.push(history.location.pathname, {
                                            step: STEPS.TRANSACTION_TYPE.label,
                                            subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                        })
                                    }}
                                >
                                    <Image src={arrow_back} preview={false} />
                                    Back
                                </Button>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Form
                                    form={form}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start'
                                    }}
                                    layout={'vertical'}
                                    initialValues={{
                                        correspondentMobileNo: props.buyer.mobileNo,
                                        correspondentEmail: props.buyer.email,
                                    }}
                                    onFinish={values => {

                                        if (values.correspondentAddress1) {
                                            values.correspondentAddress1 = removeTrailings(values.correspondentAddress1)
                                        }

                                        if (values.correspondentAddress2) {
                                            values.correspondentAddress2 = removeTrailings(values.correspondentAddress2)
                                        }

                                        if (values.correspondentAddress3) {
                                            values.correspondentAddress3 = removeTrailings(values.correspondentAddress3)
                                        }

                                        if (values.district) {

                                            districts
                                                .filter(district => district.districtCode === values.district)
                                                .map(district => {
                                                    values.district = district.districtName
                                                })
                                        }

                                        if (values.correspondentDistrict) {

                                            correspondentDistricts
                                                .filter(district => district.districtCode === values.correspondentDistrict)
                                                .map(district => {
                                                    values.correspondentDistrict = district.districtName
                                                })
                                        }

                                        const buyer = {
                                            address1: props.buyer?.address1,
                                            address2: props.buyer?.address2,
                                            address3: props.buyer?.address3,
                                            correspondentAddress1: values.correspondentAddress1 ?? '',
                                            correspondentAddress2: values.correspondentAddress2 ?? '',
                                            correspondentAddress3: values.correspondentAddress3 ?? '',
                                            correspondentPostcode: values.correspondentPostcode ?? '',
                                            correspondentState: values.correspondentState ?? '',
                                            correspondentDistrict: values.correspondentDistrict ?? '',
                                            correspondentEmail: values.correspondentEmail,
                                            correspondentMobileNo: values.correspondentMobileNo,
                                            dateOfBirth: props.buyer?.dateOfBirth,
                                            city: values.cityName? values.cityName : buyerCity,
                                            mykadCity: props.buyer.city,
                                            district: values.district,
                                            email: props.buyer?.email,
                                            gender: props.buyer?.gender,
                                            identificationNo: props.buyer?.identificationNo?.replace(/[\s*]/g, ""),
                                            name: props.buyer?.name,
                                            phoneNo: props.buyer?.phoneNo,
                                            mobileNo: props.buyer?.mobileNo,
                                            postcode: props.buyer?.postcode,
                                            state: props.buyer?.state,
                                            photo: props.buyer?.photo,
                                            buyerType: props.buyer?.buyerType,
                                            isAddressDifferent: isAddressDifferent,
                                            citizenship: props.buyer?.citizenship,
                                        }
                                        
                                        props.onChange(buyer)

                                        switch (props.transactionType) {
                                            case OUT_TRANSACTION_TYPES_IDS.I2C:
                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.AUTHORIZE_SELLER.label,
                                                    subStep: STEPS.AUTHORIZE_SELLER.subSteps.INSERT_IC
                                                })
                                                break
                                            default:

                                                history.push(history.location.pathname, {
                                                    ...history.location.state,
                                                    step: STEPS.VEHICLE_INFORMATION.label,
                                                    subStep: STEPS.VEHICLE_INFORMATION.subSteps.VEHICLE_INFO
                                                })
                                                break
                                        }
                                    }}
                                >

                                    <div style={{
                                        padding: '24px',
                                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                                        boxShadow: "0px 3px 6px #00000029",
                                        borderRadius: "4px",
                                        width: '100%',
                                        minHeight: '800px', // Set your desired minimum height here
                                        height: 'auto', // Allow the div to expand as needed
                                        display: 'flex',
                                        flexDirection: 'column',
                                        maxWidth: '100%',
                                        alignItems: 'flex-start'
                                    }}
                                    >

                                        <h2 style={{ fontWeight: 'bold', marginTop: 20 }}>Additional information</h2>
                                        <Form.Item
                                            name='correspondentMobileNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    pattern: new RegExp('^\\d{9,11}$'),
                                                    message: 'Accepts only numbers (length must be between 9 - 11)',
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>Mobile Number</p>}>
                                            <Input className='textbox-form-style'
                                                style={{ width: 500 }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name='correspondentEmail'
                                            rules={[
                                                {
                                                    required: false,
                                                    pattern: new RegExp('^(?=.{6,50}$)[a-zA-Z0-9~!$%^.&*_\\-=+}|{\\‘?#\/`]*@[a-zA-Z0-9_\\-\\.]*$'),
                                                    message: <li>
                                                        <ol>{'Must be between 6 - 50 characters'}</ol>
                                                        <ol>{'Must contain @'}</ol>
                                                        <ol>{'Allow for (a-z), (A-Z), (0-9) ~!$%^.&*_-=+}|{\‘?#/`'}</ol>
                                                    </li>
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>Email</p>}>
                                            <Input className='textbox-form-style'
                                                style={{ width: 500 }}
                                            />
                                        </Form.Item>

                                        {/* <p style={styles.label}>Current Address</p>
                                        <p style={styles.value}>{parseAddress({ ...props.buyer, state: Object.values(STATES).find(s => s.code === props.buyer?.state)?.label })}</p> */}

                                       {/*  <Form.Item
                                            name='district'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please choose district from the dropdown`
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>District</p>}
                                        >
                                            <Select
                                                className='custom-select'
                                                showSearch
                                                style={{ width: 200, height: 40 }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                loading={districtLoading}
                                            >
                                                {
                                                    districts.map(d => {
                                                        return (
                                                            <Select.Option
                                                                key={d.districtCode}
                                                                value={d.districtCode}
                                                            >
                                                                {d.districtName}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item> */}
                                        {isShowCity && <Form.Item
                                            name='cityName'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: `Please choose city from the dropdown`
                                                },
                                            ]}
                                            label={<p style={styles.formLabel}>City</p>}
                                        >
                                            <Select
                                                className='custom-select'
                                                showSearch
                                                style={{ width: 200, height: 40 }}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                                }
                                                loading={cityLoading}
                                            >
                                                {
                                                    cityList.map(d => {
                                                        return (
                                                            <Select.Option
                                                                key={d.cityCode}
                                                                value={d.cityName}
                                                            >
                                                                {d.cityName}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>}

                                        {/* <Form.Item
                                            name="isAddressDifferent"
                                            valuePropName="checked"
                                            initialValue={isAddressDifferent}
                                        >
                                            <Checkbox
                                                onChange={(e) => setIsAddressDifferent(e.target.checked)}
                                            >
                                                <p style={{ color: '#333333', font: 'normal normal 600 14px Open Sans' }}>
                                                    Current address is different than MyKad/MyPR
                                                </p>
                                            </Checkbox>
                                        </Form.Item> */}

                                        {
                                            isAddressDifferent === true && (
                                                <>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: isAddressDifferent,
                                                                message: `Current address is required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress1'
                                                        label={<p style={styles.formLabel}>Current Address</p>}>
                                                        <Input className='textbox-form-style'
                                                            style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: `Required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress2'
                                                    >
                                                        <Input className='textbox-form-style'
                                                            style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: `Required`
                                                            },
                                                        ]}
                                                        name='correspondentAddress3'
                                                    >
                                                        <Input className='textbox-form-style'
                                                            style={{ width: 500 }}
                                                        />
                                                    </Form.Item>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 10,
                                                            maxWidth: 500,
                                                        }}
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    pattern: new RegExp('^\\d{5,5}$'),
                                                                    message: `Please enter a valid postcode`
                                                                },
                                                            ]}
                                                            name='correspondentPostcode'
                                                            label={<p style={styles.formLabel}>Postcode</p>}
                                                        >
                                                            <Input className='textbox-form-style' />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={'correspondentState'}
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    message: 'Please choose state from the dropdown',
                                                                },
                                                            ]}
                                                            label={<p style={styles.formLabel}>State</p>}>
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={(stateCode) => {
                                                                    form.setFieldsValue({
                                                                        correspondentDistrict: null
                                                                    })
                                                                    setDistrictLoading(true)
                                                                    getDistrictList(stateCode).then((res) => {
                                                                        setCorrespondentDistricts(res)
                                                                    }).catch((err) => {
                                                                        err && message.error(err?.toString())
                                                                    }).finally(() => {
                                                                        setDistrictLoading(false)
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                    Object.values(STATES)
                                                                        .map(state => {
                                                                            return <Select.Option
                                                                                key={state.code}
                                                                                value={state.code}
                                                                            >
                                                                                {state.label}
                                                                            </Select.Option>
                                                                        })
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                        {/* <Form.Item
                                                            name='correspondentDistrict'
                                                            rules={[
                                                                {
                                                                    required: isAddressDifferent,
                                                                    message: `Please choose district from the dropdown`
                                                                },
                                                            ]}
                                                            label={<p style={styles.formLabel}>District</p>}
                                                        >
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                loading={districtLoading}
                                                            >
                                                                {
                                                                    correspondentDistricts.map(d => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={d.districtCode}
                                                                                value={d.districtCode}
                                                                            >
                                                                                {d.districtName}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item> */}
                                                        {isShowCity && <Form.Item
                                                            name='cityName'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Please choose city from the dropdown`
                                                                },
                                                            ]}
                                                            label={<p style={styles.formLabel}>City</p>}
                                                        >
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                                                                }
                                                                loading={cityLoading}
                                                            >
                                                                {
                                                                    cityList.map(d => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={d.cityCode}
                                                                                value={d.cityName}
                                                                            >
                                                                                {d.cityName}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>}
                                                    </div>
                                                </>
                                            )
                                        }

                                        {/* <div>
                                            <p style={styles.formLabel}>Country</p>
                                            <p style={styles.value}>Malaysia</p>
                                        </div> */}
                                    </div>
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: 32
                                        }}
                                    >
                                        <Button
                                            // size='large'
                                            // style={{
                                            //     width: 200,
                                            //     background: " #2B61C4 0% 0% no-repeat padding-box",
                                            //     boxShadow: "0px 4px 0px #1E4489",
                                            //     borderRadius: "8px",
                                            //     borderColor: 'transparent'
                                            // }}
                                            htmlType='submit'
                                            type='primary'
                                            className='rectangle-button to-the-right'
                                            disabled={isButtonDisabled}
                                        >
                                            Continue
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </>
            )
        },
    }

    const [step, setStep] = useState(STEPS.AUTHORIZE_BUYER.label)
    const [subStep, setSubStep] = useState(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <div
                style={{
                    height: '50%',
                    width: '100%',
                    border: '0px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >

                    {
                        subStep !== STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY ? (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: 'white',
                                    padding: 24,
                                    boxShadow: '0px 3px 6px #00000029',
                                    textAlign: 'center',
                                    borderRadius: 8
                                }}
                            >
                                {
                                    currentStep && currentStep.component
                                }
                                <DeviceReader
                                    forKey='buyer'
                                    nextStep={STEPS.AUTHORIZE_BUYER.label}
                                    nextSubStep={STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY}
                                    onIdentityCardSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERTING_IC)
                                    }}
                                    onWaitingThumbprint={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_BEFORE)
                                    }}
                                    onThumbprintDetected={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUBMPRINTING)
                                    }}
                                    onThumbprintFailed={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_FAILED)
                                    }}
                                    onThumbprintSuccess={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.THUMBPRINT_SUCCESS)
                                    }}
                                    onTryAgain={() => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                                    }}
                                    onFinish={async data => {

                                        let errorMessages = [];

                                        const decryptedResponse = await decryptUrlVerify(data,
                                            data.encryptString,
                                            data.forkey,
                                            getCompanyId(),
                                            "DERMALOG",
                                            user.nric.replace(/[\s*]/g, ""))
                                        if(decryptedResponse === false) {
                                            errorMessages.push("Failed to authorize session with token. Please try again.")
                                        }

                                        if (!data.name) {
                                            errorMessages.push("Failed to read the Name from MyKad. The Name read as '" + data.name + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        // if (!data.district) {
                                        //     errorMessages.push("Failed to read the District from MyKad. The District read as '" + data.district + "'. Please contact the eTukar Admin for more information.");
                                        // }

                                        if (!data.identificationNo || !/^[+]?\d+([.]\d+)?[*]?$/.test(data.identificationNo)) {
                                            errorMessages.push("Failed to read the IC No from MyKad. The IC No. read as '" + data.identificationNo + "'. Please contact the eTukar Admin for more information.");
                                        }

                                        if (errorMessages.length > 0) {
                                            for (let errorMessage of errorMessages) {
                                                message.error(errorMessage);
                                            }
                                            props.onChange({})

                                            history.push(history.location.pathname, {
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })
                                            return;
                                        }

                                        const formattedState = data?.state?.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '');
                                        let state = Object.values(STATES_FULLNAME).find(state => state.label.toLowerCase().replace(/\s/g, '').replace(/[()]/g, '') === formattedState);

                                        if (!state) state = getState(data.identificationNo)

                                        let mobileNo
                                        let _email

                                        isAccountExist(data.identificationNo.replace(/[\s*]/g, ""))
                                            .then(res => {

                                                if (!res.status) {
                                                    return {
                                                        status: false
                                                    }
                                                } else {
                                                    return getAccountDetails(data.identificationNo.replace(/[\s*]/g, ""))
                                                }

                                            })
                                            .then(res => {

                                                if (res.status == false) {
                                                    mobileNo = ''
                                                    _email = ''
                                                } else {

                                                    const { email, mobileno } = res

                                                    mobileNo = mobileno
                                                    _email = email

                                                }

                                            })
                                            .catch(err => {
                                                err && message.error(err)
                                            }).finally(() => {

                                                props.onChange({
                                                    ...props.buyer,
                                                    ...data,
                                                    state: state?.code,
                                                    mobileNo: mobileNo,
                                                    email: _email,
                                                    address1: data.address1.replace(/\0.*$/, ""),
                                                    address2: data.address2.replace(/\0.*$/, ""),
                                                    address3: data.address3.replace(/\0.*$/, ""),
                                                    name: data.name.replace(/\*/g, ''),
                                                    district: data.district.replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                                    city: data.district.replace(/[^a-zA-Z0-9\s\/-]/g, ''),
                                                    citizenship: data.citizenship
                                                })
                                                setStep(STEPS.AUTHORIZE_BUYER.label)
                                                setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.SUMMARY)
                                            })

                                    }}
                                    onDataChanged={data => { }}
                                    onError={data => {
                                        setStep(STEPS.AUTHORIZE_BUYER.label)
                                        setSubStep(STEPS.AUTHORIZE_BUYER.subSteps.INSERT_IC)
                                    }}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginTop: 20,
                                    }}
                                >
                                    {/* <Button
                                        shape='round'
                                        onClick={() => {
                                            history.push(history.location.pathname, {
                                                ...history.location.state,
                                                step: STEPS.TRANSACTION_TYPE.label,
                                                subStep: STEPS.TRANSACTION_TYPE.subSteps.TRANSACTION_TYPE
                                            })
                                        }}
                                    >
                                        Prev step
                                    </Button> */}
                                </div>
                            </div>
                        ) : <div> {
                            currentStep && currentStep.component
                        }</div>
                    }
                </div>
            </div>
        </>
    )
}

AuthorizeBuyer.propTypes = {
    buyer: propTypes.object.isRequired,
    onChange: propTypes.func.isRequired,
    transactionType: propTypes.number.isRequired
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    formLabel: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
        margin: 0
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    }
}

export default AuthorizeBuyer