import { Button } from "antd";
export const UpdateButton = (onUpdateVehicle) => {
  return (
    <Button
      type="primary"
      className="rectangle-button to-the-right"
      onClick={() => {
        onUpdateVehicle();
      }}
    >
      Update
    </Button>
  );
};

export const BackButton = (onBackButtonClick) => {
  return (
    <Button
      className="rounded-button-primary"
      onClick={() => {
        onBackButtonClick();
      }}
    >
      BACK
    </Button>
  );
};

export const JPJSubmissionButton = (onJpjSubmitButtonClick, isDisable, message) => {
  return (
    <Button
      loading={isDisable}
      isDisable={isDisable}
      size="large"
      style={{
        width: 200,
        background: "#2B61C4 0% 0% no-repeat padding-box",
        boxShadow: "0px 4px 0px #1E4489",
        borderRadius: "8px",
        borderColor: "transparent",
        float: "right",
      }}
      htmlType="submit"
      onClick={() => {
        onJpjSubmitButtonClick();
      }}
      type="primary"
    >
      {message}
    </Button>
  );
};

export const ApprovalButton = (onApproveVehicle) => {
  return (
    <Button
      type="primary"
      style={{
        // width: 200,
        background: "#2B61C4 0% 0% no-repeat padding-box",
        boxShadow: "0px 4px 0px #1E4489",
        borderRadius: "8px",
        borderColor: "transparent",
        float: "right",
        textAlign: "center",
      }}
      className="rectangle-button to-the-right"
      onClick={() => {
        onApproveVehicle();
      }}
    >
      Approve
    </Button>
  );
};

export const TextButton = (value, onButtonClick) => {
  return (
    <Button
      type="text"
      style={{
        color: "blue",
      }}
      onClick={() => onButtonClick()}
    >
      {value}
    </Button>
  );
};
