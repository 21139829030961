import React,
{
    useEffect,
    useState,
    useContext
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'antd/lib/form/Form'
import {
    Card,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Radio,
    Image,
    message,
    Checkbox,
    Divider,
    Modal,
    Skeleton,
    Progress
} from 'antd'
import { STEPS } from './steps'
import { HQ_BRANCH, STATES, iv } from '../../constants'
import {
    addUserFromAdmin,
    create1MIDAccount,
    getAccountDetails,
    getRoles,
    isAccountExist,
    updateUserFromAdmin,
    getCompanyDetails,
    getDistrictList,
    updatePhoneNumber,
    getUser,
    getRolesByUsername,
    updateProfile,
    updateEmail,
    changeOwnPassword
} from '../../services/api'
import { removeCachedStates, getToken } from '../../services/local'
import arrow_back from '../../assets/arrow_back.svg'
import {
    breakdownIc,
    parseAddress,
    stripHtml
} from '../../util'
import moment from 'moment'
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';

const AuthorizeUser = (props) => {

    const params = useParams()
    const history = useHistory()

    const [otherBranchFormPersonalDetails] = useForm()
    const [otherBranchFormAccountDetails] = useForm()
    const [changeEmailForm] = useForm()
    const [changeMobileNoForm] = useForm()

    const [subStep, setSubStep] = useState('')
    const [branchList, setBranchList] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [jpjUserRoles, setJPJUserRoles] = useState([])
    const [roles, setRoles] = useState([])
    const [districts, setDistricts] = useState([])
    const [staffInfo, setStaffInfo] = useState({})
    const [UpdateStaffLoading, setUpdateStaffLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [changeEmailLoading, setchangeEmailLoading] = useState(false)
    const [disableEmailField, setDisableEmailField] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)
    const [showChangeMobileNoModal, setShowChangeMobileNoModal] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const { formattedDob, isMale } = breakdownIc(staffInfo?.nric)
    const [showPasswordStrength, setShowPasswordStrength] = useState(false)
    const [changePasswordForm] = useForm()
    const [changePasswordLoading, setChangePasswordLoading] = useState(false)
    const [isPageRefreshed, setIsPageRefreshed] = useState(false)

    const passwordStrengthMap = {
        WEAK: {
            percent: 30,
            color: "red",
            message: "Weak",
            messageBody: "It will be easy for a computer to crack your password.",
        },
        NORMAL: {
            percent: 50,
            color: "orange",
            message: "Normal",
            messageBody: "It will take some time for a computer to crack your password.",
        },
        STRONG: {
            percent: 91,
            color: "green",
            message: "Strong",
            messageBody: "It will take a very long time for a computer to crack your password.",
        },
    };

    const [passwordStrength, setPasswordStrength] = useState(passwordStrengthMap["WEAK"])

    const handleChangePassword = () => {
        setChangePasswordLoading(true);

        const { newPassword, confirmPassword } = changePasswordForm.getFieldsValue()

        if (newPassword != confirmPassword) {
            message.error('New password & confirm password does not match!')
            setChangePasswordLoading(false)
            return
        }

        let currentPasswordCiphertext = CryptoJS.AES.encrypt('12345678', iv).toString();
        let newPasswordCiphertext = CryptoJS.AES.encrypt(newPassword, iv).toString();

        changeOwnPassword(currentPasswordCiphertext, 'true', newPasswordCiphertext, staffInfo?.nric)
            .then((res) => {
                if (res.status !== true) {
                    message.error(res.message);
                    return;
                }
                message.success(res.message);
            })
            .catch((err) => err && message.error(err))
            .finally(() => {
                setChangePasswordLoading(false);
                setShowChangePasswordModal(false);
            });
    }

    const checkPasswordStrength = () => {
        const { newPassword } = changePasswordForm.getFieldsValue();
        // Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter and one number:
        const passwordNormalRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,12}$/;
        // Minimum eight, maximum 12 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
        const passwordStrongRegex =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|~`])[A-Za-z\d!@#$%^&*()_+|~`]{8,12}$/;

        // re-render PasswordStrengthComponent everytime strength is changed
        if (newPassword) {
            setShowPasswordStrength(true);

            // if password matches normal strength regex, change strength state to normal
            passwordNormalRegex.test(newPassword) && setPasswordStrength(passwordStrengthMap["NORMAL"]);
            // if password matches strong strength regex, change stregth state to strong
            passwordStrongRegex.test(newPassword) && setPasswordStrength(passwordStrengthMap["STRONG"]);
            // otherwise, change strength to weak
            !passwordNormalRegex.test(newPassword) &&
                !passwordStrongRegex.test(newPassword) &&
                setPasswordStrength(passwordStrengthMap["WEAK"]);
        } else {
            setShowPasswordStrength(false);
        }
    };

    const PasswordStrengthComponent = () => {
        return showPasswordStrength ? (
            <>
                <Progress percent={passwordStrength.percent} strokeColor={passwordStrength.color} showInfo={false} />
                <p>
                    <span style={{ fontWeight: "bold", color: passwordStrength.color }}>
                        {passwordStrength.message}.{" "}
                    </span>
                    {passwordStrength.messageBody}
                </p>
            </>
        ) : (
            <></>
        );
    };

    const getLatestStaffInfo = () => {

        setIsLoading(true)

        getUser(props.staffId)
            .then(async res => {

                if (res.status !== '00') throw res.message

                const roles = await getRolesByUsername(res.userDetails.nricNo)
                    .then(res => {
                        const roles = res.roles?.map(r => r.name) || []
                        return roles
                    })
                    .catch(err => err && message.error(err))

                const accountDetails = await getAccountDetails(res.userDetails.nricNo)
                    .then(res => {
                        //if have message meaning user account details is empty thus, throw error
                        if (res.message) throw res.message + ' - ' + res.details[0]
                        return res
                    })
                    .catch(err => err && message.error(err))

                if (Object.keys(accountDetails).length > 0) {

                    let staffInfo = {
                        id: res.userDetails.id,
                        name: res.userDetails.personName,
                        nric: accountDetails.nric,
                        gender: accountDetails.gender,
                        dob: accountDetails.dob,
                        username: accountDetails.username,
                        mobileNo: accountDetails.mobileno,
                        // phoneNo : accountDetails.phoneno,
                        branchId: res.userDetails.branchId,
                        roles: roles,
                        address: res.address
                    }

                    setStaffInfo(staffInfo)
                }

            })
            .catch(err => err && message.error(err))
            .finally(() => setIsLoading(false))
    }

    useEffect(() => {

        const token = getToken()

        if (props.staffId && token) {

            getLatestStaffInfo()

        }

    }, [props.staffId])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (params.mode == 'edit') setIsEdit(true)
        else setIsEdit(false)

    }, [params])

    const rolesMap = {
        OWNER: { title: "Owner", description: ["Can do everything"], required: "" },
        VALUER: {
            title: "Level 1 (Valuer)",
            description: ["Perform IN transactions only", "No payment allowed"],
            required: "",
        },
        SALES: {
            title: "Level 2 (Sales)",
            description: ["Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / Inquiry Only", "Able to make IN payment only"],
            required: "",
        },
        ADMIN: {
            title: "Level 3 (Admin)",
            description: [
                "Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / Inquiry only",
                "⁠Able to make IN & I2I payment only",
                "⁠Able to perform Inter-Branch transfer",
            ],
            required: "",
        },
        SALES_MANAGER: {
            title: "Level 4 (Sales Manager)",
            description: [
                "Perform IN, I2I, OUT & M2M Transaction until JPJ Submission / inquiry",
                "⁠Able to make IN & I2I payment only",
                "Able to Submit / Approve M2M to buyer",
                "⁠Able to Add Users",
                "Able to perform Inter-Branch transfer",
            ],
            required: "",
        },
        GENERAL_MANAGER: {
            title: "Level 5 (General Manager)",
            description: ["Perform IN, i2i, OUT & M2M transactions", "Able to make ALL payment*"],
            required: "",
        },
        MYEG_RUNNER_2: {
            title: "Partner (Runner)",
            description: ["Perform i2i transactions", "Able to make i2i payment*"],
            required: "",
        },
        MYEG_RUNNER_1: {
            title: "Assistant (Runner)",
            description: ["Perform i2i transactions"],
            required: "",
        },
        MYEG_OWNER_RUNNER: {
            title: "Owner (Runner)",
            description: ["Perform i2i transactions", "Able to make i2i payment*", "Able to add users"],
            required: "",
        },
        JPJ_APPROVER: {
            title: "JPJ Approver",
            description: "",
            required: "",
        },
        JPJ_REPRESENTATIVE: {
            title: "JPJ Representative",
            description: "",
            required: "",
        }
    }

    const rearrangeRoles = (from, to, ...allRoles) =>
        from === to ? allRoles : (allRoles.splice(to, 0, ...allRoles.splice(from, 1)), allRoles)

    const handleAddNewUserRemote = () => {

        const { newUserName,
            newUserNric,
            newUserGender,
            newUserDobDay,
            newUserDobMonth,
            newUserDobYear } = otherBranchFormPersonalDetails.getFieldsValue()

        const {
            newUserEmail,
            newUserPhoneNo,
            newUserBranchId } = otherBranchFormAccountDetails.getFieldsValue()

        let _newUserEmail = newUserEmail.trim()
        let roles = []
        userRoles.length > 0 && roles.push(userRoles)

        if (jpjUserRoles.length > 0) {
            for (let jpjUserRole of jpjUserRoles) {
                roles.push(jpjUserRole)
            }
        }

        // let localFormattedDob = newUserDobDay + "-" + newUserDobMonth + "-" + newUserDobYear
        let localFormattedDob = newUserDobYear + "-" + newUserDobMonth + "-" + newUserDobDay

        isAccountExist(newUserNric)
            .then((res) => {

                if (res.status === false) {

                    return create1MIDAccount(//create 1mid account with dummy
                        'dummyAddres1',
                        '',
                        '',
                        localFormattedDob,
                        '',
                        '',
                        'dummyCity',
                        stripHtml(_newUserEmail),
                        stripHtml(_newUserEmail),
                        newUserGender,
                        stripHtml(newUserPhoneNo),
                        stripHtml(newUserName),
                        stripHtml(newUserNric),
                        true,
                        stripHtml(newUserPhoneNo),
                        '99999',
                        '',
                        '',
                        '12')

                } else if (res.status === true) {

                    return {
                        status: true
                    }

                } else {
                    throw res.message
                }

            })
            .then(res => {

                if (!res.status) {

                    let msg = res.message

                    if ('Sorry, an account already exists with email address - ' === res.message) {
                        msg += _newUserEmail
                    }

                    throw msg
                }

                return getAccountDetails(newUserNric)//get 1mid account details

            })
            .then(res => {

                const { nric, name } = res

                return addUserFromAdmin(//create new etukar user
                    newUserBranchId,
                    props?.company?.id,
                    stripHtml(newUserPhoneNo),
                    stripHtml(_newUserEmail),
                    stripHtml(nric.replace(/[\s*]/g, "")),
                    stripHtml(name),
                    roles)

            })
            .then(res => {

                if (!res.status) throw res.message

                message.success(res.message)

                return getCompanyDetails(props.company.coRegNo)

            })
            .then(res => {

                if (res.status != '00') throw res.message

                if (Object.keys(res).length > 0) {

                    let resultCompany = []
                    let companyDetails = []

                    companyDetails.push(res.companyDetails)
                    companyDetails[0]['users'] = res.users

                    resultCompany.push(companyDetails[0])

                    history.push(`/adminCompany/view`, {
                        companyInfo: resultCompany[0]
                    })
                }

            })
            .catch((err) => {

                err && message.error(err)

            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleUpdateUser = () => {

        const address1 = otherBranchFormPersonalDetails.getFieldValue('address1')
        const address2 = otherBranchFormPersonalDetails.getFieldValue('address2')
        const address3 = otherBranchFormPersonalDetails.getFieldValue('address3')
        const postcode = otherBranchFormPersonalDetails.getFieldValue('postcode')
        const state = otherBranchFormPersonalDetails.getFieldValue('state')
        const district = otherBranchFormPersonalDetails.getFieldValue('district')

        const newUserPhoneNo = otherBranchFormAccountDetails.getFieldValue('newUserPhoneNo')
        const newUserBranchId = otherBranchFormAccountDetails.getFieldValue('newUserBranchId')

        let roles = []
        userRoles.length > 0 && roles.push(userRoles)

        if (jpjUserRoles.length > 0) {
            for (let jpjUserRole of jpjUserRoles) {
                roles.push(jpjUserRole)
            }
        }

        updateUserFromAdmin(
            newUserBranchId,
            props.company.id,
            newUserPhoneNo,
            staffInfo.username,
            staffInfo.nric,
            staffInfo.id,
            staffInfo.name,
            roles)
            .then(res => {

                if (!res.status) throw res.message

                return updateProfile(
                    stripHtml(address1),
                    stripHtml(address2),
                    stripHtml(address3),
                    district,
                    newUserPhoneNo,
                    'true',
                    staffInfo.nric,
                    newUserPhoneNo,
                    stripHtml(postcode),
                    state
                )

            })
            .then(res => {

                if (!res.status) throw (!res.details || res.details.length === 0) ? res.message : res.details.join(', ');

                message.success(res.message)

                return getCompanyDetails(props.company.coRegNo)

            })
            .then(res => {

                if (res.status != '00') throw res.message

                if (Object.keys(res).length > 0) {

                    let resultCompany = []
                    let companyDetails = []

                    companyDetails.push(res.companyDetails)
                    companyDetails[0]['users'] = res.users

                    resultCompany.push(companyDetails[0])

                    history.push(`/adminCompany/view`, {
                        companyInfo: resultCompany[0]
                    })
                }
            })
            .catch(err => {
                err && message.error(err)
            })
            .finally(() => {
                setUpdateStaffLoading(false)
            })
    }

    const onChangeNric = () => {

        const { newUserNric } = otherBranchFormPersonalDetails.getFieldsValue()

        if (newUserNric.length === 12 || newUserNric.length === 13) {

            getAccountDetails(newUserNric).then(res => {

                const { mobileno, name, username } = res

                if (name) {

                    otherBranchFormPersonalDetails.setFieldsValue({
                        newUserName: name
                    })
                } else {
                    otherBranchFormPersonalDetails.resetFields([
                        'newUserName',
                    ])
                }

                if (mobileno) {

                    otherBranchFormAccountDetails.setFieldsValue({
                        newUserPhoneNo: mobileno
                    })
                } else {

                    otherBranchFormAccountDetails.resetFields([
                        'newUserPhoneNo'
                    ])
                }

                if (username) {

                    otherBranchFormAccountDetails.setFieldsValue({
                        newUserEmail: username
                    })

                    isEdit ? setDisableEmailField(false) : setDisableEmailField(true)

                } else {

                    otherBranchFormAccountDetails.resetFields([
                        'newUserEmail'
                    ])

                    setDisableEmailField(false)
                }

            })
            // .catch(err => err && message.error(err.toString()))

            const { formattedDob, isMale } = breakdownIc(newUserNric)

            otherBranchFormPersonalDetails.setFieldsValue({
                newUserGender: isMale ? 'MALE' : 'FEMALE',
                newUserDobDay: moment(formattedDob).format('DD'),
                newUserDobMonth: moment(formattedDob).format('MM'),
                newUserDobYear: moment(formattedDob).format('YYYY')
            })

        } else {

            otherBranchFormPersonalDetails.resetFields([
                'newUserName',
                'newUserGender',
                'newUserDobDay',
                'newUserDobMonth',
                'newUserDobYear'
            ])

            otherBranchFormAccountDetails.resetFields([
                'newUserEmail',
                'newUserPhoneNo'
            ])

            setDisableEmailField(false)

        }
    }

    useEffect(() => {

        let alteredRoles

        const token = getToken()

        if (!token) return

        getRoles()
            .then(res => {

                if (!res.status) throw res.message
                // let filteredRoles = res.roles.filter((role) => role.name !== 'MYEG_RUNNER_1' && role.name !== 'MYEG_RUNNER_2')
                let filteredRoles = res.roles

                alteredRoles = rearrangeRoles(2, 0, ...filteredRoles.filter((role) => !role.name.toLowerCase().includes('jpj')))

                alteredRoles = rearrangeRoles(3, 1, ...alteredRoles)
                alteredRoles = rearrangeRoles(3, 2, ...alteredRoles)
                alteredRoles = rearrangeRoles(3, 5, ...alteredRoles)

                alteredRoles.push(...filteredRoles.filter((role) => role.name.toLowerCase().includes('jpj')))
                setRoles(alteredRoles.map((r) => r.name))
            })
            .catch(err => err && message.error(err))

        window.onbeforeunload = () => {
            removeCachedStates()
            history.push(history.location.pathname)
        }

    }, [])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (props?.subStep) setSubStep(props?.subStep)

    }, [props])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (props?.company?.branchDetails) {

            let activeBranches = []
            activeBranches = props?.company?.branchDetails.filter(branch => branch.is_active === true)

            if (activeBranches.length > 0) {

                activeBranches.push(HQ_BRANCH)

                activeBranches.sort(
                    (a, b) => {
                        let fa = a.branchName.toLowerCase(),
                            fb = b.branchName.toLowerCase();

                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    }
                )

                setBranchList(activeBranches)

            } else {
                setBranchList([HQ_BRANCH])
            }

        } else {

            setBranchList([HQ_BRANCH])

        }

    }, [props])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (Object.keys(staffInfo).length > 0) {

            otherBranchFormPersonalDetails.setFieldsValue({
                address1: staffInfo?.address?.line1,
                address2: staffInfo?.address?.line2,
                address3: staffInfo?.address?.line3,
                postcode: staffInfo?.address?.postcode
            })

            otherBranchFormAccountDetails.setFieldsValue({
                newUserEmail: staffInfo.username,
                newUserPhoneNo: staffInfo.mobileNo,
                newUserBranchId: staffInfo.branchId
            })

            setUserRoles(staffInfo?.roles?.filter(r => r && !r.toLowerCase().includes('jpj'))[0])
            setJPJUserRoles(staffInfo?.roles?.filter(r => r && r.toLowerCase().includes('jpj')))
        }

    }, [staffInfo])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (Object.keys(staffInfo).length > 0) {

            let stateCode = ''

            if (staffInfo?.address?.state) {

                stateCode = Object.values(STATES).find(state => state.label.toLowerCase().includes(staffInfo?.address?.state.toLowerCase()))?.code
            } else {

                stateCode = Object.values(STATES).find(state => state.code.includes(staffInfo?.address?.stateCode))?.code
            }


            if (stateCode) {

                otherBranchFormPersonalDetails.setFieldsValue({
                    state: stateCode,
                })

                setIsLoading(true)

                getDistrictList(stateCode).then((res) => {
                    setDistricts(res)
                }).catch((err) => {
                    err && message.error(err?.toString())
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        }

    }, [staffInfo])

    useEffect(() => {
        const token = getToken()
        if (!token) return
        if (Object.keys(districts).length > 0) {

            districts
                .filter(district => district.districtName.toUpperCase() === staffInfo.address.city.toUpperCase())
                .map(district => {
                    otherBranchFormPersonalDetails.setFieldsValue({
                        district: district.districtName,
                    })
                })

        }

    }, [districts])

    const COMPONENT_STEPS = {
        [STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF]: {
            component: (
                <>
                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                        <Col span={12}>
                            <Card className='form-background' style={{ textAlign: "left", height: '100%' }} >
                                <Form
                                    layout={'vertical'}
                                    form={otherBranchFormPersonalDetails}
                                    scrollToFirstError
                                    requiredMark="optional"
                                    id='otherBranchFormPersonalDetails'
                                    initialValues={{
                                        newUserName: '',
                                        newUserNric: '',
                                        newUserGender: '',
                                        newUserDobDay: '',
                                        newUserDobMonth: '',
                                        newUserDobYear: ''
                                    }}>
                                    <h2>Personal Details</h2>

                                    <Form.Item
                                        label="Name"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserName"
                                        rules={[{ required: true, message: "Please provide user Name." }]}>
                                        <Input
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="text" />
                                    </Form.Item>
                                    {/* /^[+]?\d+([.]\d+)?[*]?$/ */}
                                    <Form.Item
                                        label="NRIC"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserNric"
                                        rules={[{ required: true, message: "Please provide user NRIC." }, { pattern: /^[+]?\d+([.]\d+)?$/, message: "Please enter a valid NRIC" }]}>
                                        <Input
                                            onChange={onChangeNric}
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="text" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Gender"
                                        style={{ font: "italic normal normal 16px Open Sans" }}
                                        name={"newUserGender"}
                                        rules={[{ required: true, message: "Please select Gender" }]}
                                    >
                                        <Radio.Group
                                            buttonStyle={'solid'}
                                            style={{ width: '100%' }}
                                        >
                                            <Radio
                                                className='custom-radio'
                                                style={{ marginRight: 0, width: '30%', font: "normal normal bold 14px Open Sans" }}
                                                value={'MALE'}>MALE</Radio>
                                            <Radio
                                                className='custom-radio'
                                                style={{ width: '30%', font: "normal normal bold 14px Open Sans" }}
                                                value={'FEMALE'} defaultChecked>FEMALE</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Row gutter={[16, 16]}>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Day"
                                                style={styles.label}
                                                name={"newUserDobDay"}
                                                rules={[{ required: true, message: "Please provide a Day" }, { pattern: /(0[1-9]|[12]\d|3[01])/, message: "Please enter a valid Day" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Month"
                                                style={styles.label}
                                                name={"newUserDobMonth"}
                                                rules={[{ required: true, message: "Please provide a Month" }, { pattern: /^(0?[1-9]|1[012])$/, message: "Please enter a valid Month" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                label="Year"
                                                style={styles.label}
                                                name={"newUserDobYear"}
                                                rules={[{ required: true, message: "Please provide a Year" }, { pattern: /^\d{4}$/, message: "Please enter a valid Year" }]}>
                                                <Input
                                                    className='form-input-field'
                                                    style={{ font: "normal normal bold 14px Open Sans" }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card className='form-background' style={{ textAlign: "left", height: "100%" }}>
                                <Form
                                    form={otherBranchFormAccountDetails}
                                    layout="vertical"
                                    scrollToFirstError
                                    requiredMark="optional"
                                    id='emailPhoneForm'
                                    initialValues={{
                                        newUserEmail: '',
                                        newUserPhoneNo: '',
                                        newUserBranchId: ''
                                    }}>
                                    <h2>Account Detail</h2>
                                    <Form.Item
                                        label="Email"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserEmail"
                                        rules={[{ required: true, message: "Please provide an email address." },
                                        { max: 50, message: 'Email must be maximum 50 characters.' }]}>
                                        <Input
                                            disabled={disableEmailField}
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="email" />
                                    </Form.Item>
                                    <p style={styles.disclaimer}>User need to use this email to login.</p>
                                    <p style={styles.disclaimer}>Once an account is created, a link will be emailed to this user's email address above to set a password for his/her account.</p>
                                    <Form.Item
                                        label="Phone Number"
                                        style={styles.label}
                                        name="newUserPhoneNo"
                                        rules={[{ required: true, message: "Please provide a phone number" }, { pattern: /^(01)[0-46-9][0-9]{7,8}$/, message: "Please enter a valid phone number" }]}>
                                        <Input
                                            className='form-input-field'
                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="tel" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Branch"
                                        style={styles.label}
                                        name={"newUserBranchId"}
                                        rules={[{ required: true, message: "Please select a branch" }]}
                                    >
                                        <Select placeholder="Choose a branch"
                                            className='custom-select'
                                            style={{ width: "60%", font: "normal normal normal 14px Open Sans" }}>
                                            {branchList && branchList.filter(branch => branch.is_active ? branch.is_active === true : branch.is_hq === true).map((branch) => {
                                                return (
                                                    <Select.Option key={branch.id} value={branch.id}>
                                                        {branch.branchName}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                    </Row>

                    <Card className='form-background' style={{ textAlign: "left", margin: "16px 0px 25px 0px" }}>
                        <Row>
                            <Col span={12}><h2 style={{ fontWeight: 'bold' }}>Twocar Role Permission</h2></Col>
                            {/* <Col span={12}><h2 style={{ fontWeight: 'bold' }}>MySikap Role (Not Mandatory)</h2></Col> */}
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Radio.Group
                                    value={userRoles || ''}
                                    onChange={e => {
                                        setUserRoles(e.target.value)
                                        // props.onChange({
                                        //     ...props.branchUser,
                                        //     roles: Array(e.target.value, jpjUserRoles)
                                        // })
                                    }}

                                    style={{ width: "100%" }}
                                >
                                    {roles.length > 0 && roles
                                        // .filter((role) => !role.toLowerCase().includes('jpj') && !role.toLowerCase().includes('owner'))
                                        .filter((role) => !role.toLowerCase().includes('jpj'))
                                        .map((role) => (
                                            <Radio className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                <ul style={{ paddingLeft: 14, marginBottom: 0, marginTop: 10 }}>
                                                    {rolesMap[role].description.map((description, index) => (
                                                        <li key={`${rolesMap[role].title}${index}`}>
                                                            {description}
                                                        </li>
                                                    ))}
                                                </ul>
                                                <p style={{ fontStyle: "italic" }}>{rolesMap[role].required}</p>
                                            </Radio>
                                        ))}
                                </Radio.Group>
                            </Col>
                            <Col span={12}>
                               {/*  <Checkbox.Group
                                    value={jpjUserRoles || ''}
                                    onChange={e => {
                                        setJPJUserRoles(e)
                                        // props.onChange({
                                        //     ...props.branchUser,
                                        //     roles: Array(userRoles, e.target.value)
                                        // })
                                    }}
                                    style={{ width: "100%" }}
                                >
                                    {roles
                                        .filter((role) => role.toLowerCase().includes("jpj"))
                                        .map((role) => (
                                            <Checkbox className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                            </Checkbox>
                                        ))}
                                </Checkbox.Group> */}
                            </Col>
                        </Row>
                    </Card>

                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button
                            className='back-button buttons'
                            shape='round'
                            onClick={async () => {

                                const resCompanyDetails = await getCompanyDetails(props.company.coRegNo)
                                    .then(res => {

                                        if (res.status != '00') throw res.message

                                        return res

                                    })
                                    .catch(err => {
                                        err && message.error(err)
                                    })

                                if (Object.keys(resCompanyDetails).length > 0) {

                                    let resultCompany = []
                                    let companyDetails = []

                                    companyDetails.push(resCompanyDetails.companyDetails)
                                    companyDetails[0]['users'] = resCompanyDetails.users

                                    resultCompany.push(companyDetails[0])

                                    history.push(`/adminCompany/view`, {
                                        companyInfo: resultCompany[0]
                                    })
                                }
                            }}
                        >
                            <Image src={arrow_back} preview={false} />
                            BACK
                        </Button>
                        <Button
                            loading={isLoading}
                            className='add-new-user-button'
                            size='large'
                            style={{
                                visibility: "visible",
                                width: 200,
                                marginBottom: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            type='primary'
                            onClick={() => {
                                setIsLoading(true)
                                otherBranchFormPersonalDetails
                                    .validateFields([
                                        "newUserName",
                                        "newUserNric",
                                        "newUserGender",
                                        "newUserDobDay",
                                        "newUserDobMonth",
                                        "newUserDobYear"])
                                    .then(() => {

                                        return otherBranchFormAccountDetails
                                            .validateFields([
                                                "newUserEmail",
                                                "newUserPhoneNo",
                                                "newUserBranchId"])

                                    })
                                    .then(() => handleAddNewUserRemote())
                                    .catch((err) => {
                                        setIsLoading(false)
                                        window.document.getElementById("otherBranchFormAccountDetails")?.scrollIntoView()
                                        window.document.getElementById("otherBranchFormPersonalDetails").scrollIntoView()
                                        throw err;
                                    })
                            }}
                        >Create Account
                        </Button>
                    </Row>
                </>
            )
        },
        [STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF]: {
            component: (
                <>

                    {
                        isLoading
                            ?
                            (
                                <Skeleton active />
                            )
                            :
                            (
                                <>
                                    <Row gutter={[16, 16]} style={{ alignItems: "stretch" }}>
                                        <Col span={12}>
                                            <Card className='form-background' style={{ textAlign: "left", height: '100%' }} >
                                                <Form
                                                    layout={'vertical'}
                                                    form={otherBranchFormPersonalDetails}
                                                    scrollToFirstError
                                                    requiredMark="optional"
                                                    id='otherBranchFormPersonalDetails'
                                                    initialValues={{
                                                        address1: '',
                                                        address2: '',
                                                        address3: '',
                                                        postcode: '',
                                                        state: '',
                                                        district: ''
                                                    }}>
                                                    <h2 style={{ fontWeight: 'bold' }}>Personal Details</h2>

                                                    <p style={styles.label}>Name</p>
                                                    <p style={styles.value}>{staffInfo?.name}</p>
                                                    <p style={styles.label}>NRIC</p>
                                                    <p style={styles.value}>{staffInfo?.nric}</p>
                                                    <p style={styles.label}>Date of Birth</p>
                                                    <p style={styles.value}>{formattedDob}</p>
                                                    <p style={styles.label}>Gender</p>
                                                    <p style={styles.value}>{isMale ? 'MALE' : 'FEMALE'}</p>
                                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: `Address not valid`,
                                                                // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                                whitespace: true
                                                            },
                                                        ]}
                                                        name='address1'
                                                        label={<p style={styles.label}>Address</p>}>
                                                        <Input
                                                            className='form-input-field'
                                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                                            type="text" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: `Address not valid`,
                                                                // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                            },
                                                        ]}
                                                        name='address2'
                                                    >
                                                        <Input
                                                            className='form-input-field'
                                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                                            type="text" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        rules={[
                                                            {
                                                                required: false,
                                                                // pattern: new RegExp('^[a-zA-Z 0-9]{2,20}$'),
                                                                message: `Address not valid`
                                                            },
                                                        ]}
                                                        name='address3'
                                                    >
                                                        <Input
                                                            className='form-input-field'
                                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                                            type="text" />
                                                    </Form.Item>

                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 10,
                                                            maxWidth: 500,
                                                        }}
                                                    >
                                                        <Form.Item
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Postcode is required`
                                                                },
                                                            ]}
                                                            name='postcode'
                                                            label={<p style={styles.label}>Postcode</p>}
                                                        >
                                                            <Input className='textbox-form-style' />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name={'state'}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please choose state from the dropdown',
                                                                },
                                                            ]}
                                                            label={<p style={styles.label}>State</p>}>
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                onChange={(stateCode) => {
                                                                    otherBranchFormPersonalDetails.setFieldsValue({
                                                                        district: null
                                                                    })
                                                                    getDistrictList(stateCode).then((res) => {
                                                                        setDistricts(res)
                                                                    }).catch((err) => {
                                                                        err && message.error(err?.toString())
                                                                    }).finally(() => {
                                                                    })
                                                                }}
                                                            >
                                                                {
                                                                    Object.values(STATES)
                                                                        .map(state => {
                                                                            return <Select.Option
                                                                                key={state.code}
                                                                                value={state.code}
                                                                            >
                                                                                {state.label}
                                                                            </Select.Option>
                                                                        })
                                                                }
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            name='district'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: `Please choose district from the dropdown`
                                                                },
                                                            ]}
                                                            label={<p style={styles.label}>District</p>}
                                                        >
                                                            <Select
                                                                className='custom-select'
                                                                showSearch
                                                                style={{ width: 200, height: 40 }}
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                loading={isLoading}
                                                            >
                                                                {
                                                                    districts.map(d => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={d.districtName}
                                                                                value={d.districtName}
                                                                            >
                                                                                {d.districtName}
                                                                            </Select.Option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Form>
                                            </Card>

                                        </Col>

                                        <Col span={12}>
                                            <Card className='form-background' style={{ textAlign: "left", height: "100%" }}>
                                                <Form
                                                    form={otherBranchFormAccountDetails}
                                                    layout="vertical"
                                                    scrollToFirstError
                                                    requiredMark="optional"
                                                    id='emailPhoneForm'
                                                    initialValues={{
                                                        newUserEmail: '',
                                                        newUserPhoneNo: '',
                                                        newUserBranchId: ''
                                                    }}>
                                                    <h2 style={{ fontWeight: 'bold' }}>Account Detail</h2>
                                                    {/* <Form.Item
                                        label="Email"
                                        style={{ marginBottom: 0, font: "italic normal normal 16px Open Sans" }}
                                        name="newUserEmail"
                                        rules={[{ required: true, message: "Please provide an email address." },
                                        { max: 50, message: 'Email must be maximum 50 characters.' }]}>
                                        <Input
                                            disabled={disableEmailField}
                                            className='form-input-field'
                                            style={{ marginBottom: "15px", width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="email" />
                                    </Form.Item>
                                    <p style={styles.disclaimer}>User need to use this email to login.</p>
                                    <p style={styles.disclaimer}>Once an account is created, a link will be emailed to this user's email address above to set a password for his/her account.</p>
                                    <Form.Item
                                        label="Phone Number"
                                        style={styles.label}
                                        name="newUserPhoneNo"
                                        rules={[{ required: true, message: "Please provide a phone number" }, { pattern: /^(01)[0-46-9][0-9]{7,8}$/, message: "Please enter a valid phone number" }]}>
                                        <Input
                                            className='form-input-field'
                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                            type="tel" />
                                    </Form.Item> */}
                                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Col span={12}>
                                                            <p style={styles.label}>Email as Login ID</p>
                                                            <p style={{ ...styles.value, wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                                                                {staffInfo?.username}
                                                            </p>
                                                        </Col>

                                                        <Col span={12} style={{ textAlign: "right" }}>
                                                            <Button type="link" onClick={() => setShowChangeEmailModal(true)}>CHANGE USERNAME/E-MAIL</Button>
                                                        </Col>

                                                    </Row>
                                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                                    <Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Col span={12}>
                                                            <p style={styles.label}>Password</p>
                                                        </Col>
                                                        <Col span={12} style={{ textAlign: "right" }}>
                                                            <Button type="link" onClick={() => setShowChangePasswordModal(true)}>
                                                                CHANGE PASSWORD
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                                    {/* <Row style={{ display: "flex", alignItems: "center" }}>
                                                        <Col span={12}>
                                                            <p style={styles.label}>Phone number</p>
                                                            <p style={styles.value}>
                                                                {staffInfo?.mobileNo}
                                                            </p>
                                                        </Col>
                                                        <Col span={12} style={{ textAlign: "right" }}>
                                                            <Button type="link" onClick={() => setShowChangeMobileNoModal(true)}>CHANGE NUMBER</Button>
                                                        </Col>
                                                    </Row> */}
                                                    <Form.Item
                                                        label="Phone Number"
                                                        style={styles.label}
                                                        name="newUserPhoneNo"
                                                        rules={[{ required: true, message: "Please provide a phone number" }, { pattern: /^(01)[0-46-9][0-9]{7,8}$/, message: "Please enter a valid phone number" }]}>
                                                        <Input
                                                            className='form-input-field'
                                                            style={{ width: "60%", font: "normal normal bold 14px Open Sans" }}
                                                            type="tel" />
                                                    </Form.Item>
                                                    <Divider style={{ borderTop: "1px solid #00000050" }} />
                                                    <Form.Item
                                                        label="Branch"
                                                        style={styles.label}
                                                        name={"newUserBranchId"}
                                                        rules={[{ required: true, message: "Please select a branch" }]}
                                                    >
                                                        <Select placeholder="Choose a branch"
                                                            className='custom-select'
                                                            style={{ width: "60%", font: "normal normal normal 14px Open Sans" }}>
                                                            {branchList && branchList.filter(branch => branch.is_active ? branch.is_active === true : branch.is_hq === true).map((branch) => {
                                                                return (
                                                                    <Select.Option key={branch.id} value={branch.id}>
                                                                        {branch.branchName}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Form>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Card className='form-background' style={{ textAlign: "left", margin: "16px 0px 25px 0px" }}>
                                        <Row>
                                            <Col span={12}><h2 style={{ fontWeight: 'bold' }}>Twocar Role Permission</h2></Col>
                                            {/* <Col span={12}><h2 style={{ fontWeight: 'bold' }}>MySikap Role (Not Mandatory)</h2></Col> */}
                                        </Row>
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Radio.Group
                                                    value={userRoles || ''}
                                                    onChange={e => {
                                                        setUserRoles(e.target.value)
                                                        // props.onChange({
                                                        //     ...props.staffInfo,
                                                        //     roles: Array(e.target.value, jpjUserRoles)
                                                        // })
                                                    }}

                                                    style={{ width: "100%" }}
                                                >
                                                    {roles.length > 0 && roles
                                                        // .filter((role) => !role.toLowerCase().includes('jpj') && !role.toLowerCase().includes('owner'))
                                                        .filter((role) => !role.toLowerCase().includes('jpj'))
                                                        .map((role) => (
                                                            <Radio className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                                <ul style={{ paddingLeft: 14, marginBottom: 0, marginTop: 10 }}>
                                                                    {rolesMap[role].description.map((description, index) => (
                                                                        <li key={`${rolesMap[role].title}${index}`}>
                                                                            {description}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <p style={{ fontStyle: "italic" }}>{rolesMap[role].required}</p>
                                                            </Radio>
                                                        ))}
                                                </Radio.Group>
                                            </Col>
                                            <Col span={12}>
                                                {/* <Checkbox.Group
                                                    value={jpjUserRoles || ''}
                                                    onChange={e => {
                                                        setJPJUserRoles(e)
                                                        // props.onChange({
                                                        //     ...props.staffInfo,
                                                        //     roles: Array(userRoles, e.target.value)
                                                        // })
                                                    }}
                                                    style={{ width: "100%" }}
                                                >
                                                    {roles
                                                        .filter((role) => role.toLowerCase().includes("jpj"))
                                                        .map((role) => (
                                                            <Checkbox className='form-input-field' style={{ width: "100%", marginBottom: "16px" }} key={role} value={role}>
                                                                <span style={{ font: 'normal normal 600 14px/24px Open Sans', color: '#333333' }}>{rolesMap[role].title}</span>
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </Checkbox>
                                                        ))}
                                                </Checkbox.Group> */}
                                            </Col>
                                        </Row>
                                    </Card>

                                    <Row style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Button
                                            className='back-button buttons'
                                            shape='round'
                                            onClick={async () => {
                                                const resCompanyDetails = await getCompanyDetails(props.company.coRegNo)
                                                    .then(res => {

                                                        if (res.status != '00') throw res.message

                                                        return res

                                                    })
                                                    .catch(err => {
                                                        err && message.error(err)
                                                    })

                                                if (Object.keys(resCompanyDetails).length > 0) {

                                                    let resultCompany = []
                                                    let companyDetails = []

                                                    companyDetails.push(resCompanyDetails.companyDetails)
                                                    companyDetails[0]['users'] = resCompanyDetails.users

                                                    resultCompany.push(companyDetails[0])

                                                    history.push(`/adminCompany/view`, {
                                                        companyInfo: resultCompany[0]
                                                    })
                                                }
                                            }}
                                        >
                                            <Image src={arrow_back} preview={false} />
                                            BACK
                                        </Button>
                                        <Button
                                            loading={UpdateStaffLoading}
                                            className='add-new-user-button'
                                            size='large'
                                            style={{
                                                visibility: "visible",
                                                width: 200,
                                                marginBottom: 15,
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                            type='primary'
                                            onClick={() => {
                                                setUpdateStaffLoading(true)
                                                otherBranchFormPersonalDetails
                                                    .validateFields([
                                                        "address1",
                                                        "address2",
                                                        "address3",
                                                        "postcode",
                                                        "state",
                                                        "district"])
                                                    .then(() => {

                                                        return otherBranchFormAccountDetails
                                                            .validateFields(["newUserBranchId"])

                                                    })
                                                    .then(() => handleUpdateUser())
                                                    .catch((err) => {
                                                        setUpdateStaffLoading(false)
                                                        window.document.getElementById("otherBranchFormAccountDetails")?.scrollIntoView()
                                                        window.document.getElementById("otherBranchFormPersonalDetails").scrollIntoView()
                                                        throw err;
                                                    })
                                            }}
                                        >Update Account
                                        </Button>
                                    </Row>
                                </>
                            )
                    }

                </>
            )
        }
    }

    const onChangeEmail = () => {

        setchangeEmailLoading(true)
        setShowChangeEmailModal(true)

        const { currentEmail, newEmail, confirmNewEmail } = changeEmailForm.getFieldsValue();

        let _newEmail = newEmail.trim()
        let _comfirmEmail = confirmNewEmail.trim()

        if (_newEmail != _comfirmEmail) {

            message.error('The new email and confirmation email do not match.')

            setShowChangeEmailModal(false)
            return
        }

        //dummy password for admin dashboard update email
        updateEmail(currentEmail, '12345678', true, _newEmail)
            .then((res) => {

                if (!res.status) throw res.message

                message.success(res.message)
                changeEmailForm.resetFields()

            })
            .catch((err) => err && message.error(err))
            .finally(() => {
                getLatestStaffInfo()
                setShowChangeEmailModal(false)
            })

        // updateEmail(currentEmail, '', 'false', _newEmail)
        //     .then((res) => {

        //         if (!res.status) throw res.message

        //         return getRolesByUsername(staffInfo?.identificationNo)

        //     })
        //     .then(res => {

        //         if (!res.status) throw res.message

        //         const userRoles = res.roles.map((role) => role.name)

        //         return updateUserFromAdmin(
        //             staffInfo.branchId,
        //             props.company.id,
        //             staffInfo.mobileNo,
        //             staffInfo.username,
        //             staffInfo.nric,
        //             staffInfo.id,
        //             staffInfo.name,
        //             userRoles
        //         )

        //     })
        //     .then(res => {

        //         if (!res.status) throw res.message

        //         message.success(res.message)
        //         changeEmailForm.resetFields()

        //     })
        //     .catch((err) => err && message.error(err))
        //     .finally(() => setIsLoading(false))
    }

    const onChangeMobileNo = () => {

        setShowChangeMobileNoModal(false)

        const { newPhoneNo } = changeMobileNoForm.getFieldsValue()

        updatePhoneNumber(staffInfo?.nric, newPhoneNo)
            .then(async res => {

                if (!res.status) throw res.message

                message.success(res.message)

            }).catch(err => {

                err && message.error(err)

            }).finally(() => {

                getLatestStaffInfo()
                setShowChangeMobileNoModal(false)

            })
    }

    const currentStep = COMPONENT_STEPS[subStep]

    return (
        <>
            <div
                className={
                    subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF &&
                        subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF ? 'form-background' : ""
                }
                style={{
                    height: '50%',
                    width: '100%',
                    textAlign:
                        subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF &&
                            subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF ? "center" : "left",
                    padding:
                        subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF &&
                            subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF ? "20px" : "0px",
                    marginBottom:
                        subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.ADD_STAFF &&
                            subStep !== STEPS.AUTHORIZE_ADMIN_COMPANY_USER.subSteps.EDIT_STAFF ? "10px" : "0px"
                }}
            >
                {
                    currentStep && currentStep.component
                }
            </div>

            {/* change email/username modal */}
            <Modal
                visible={showChangeEmailModal}
                footer={null}
                onCancel={() => { setShowChangeEmailModal(false) }}
                destroyOnClose
                afterClose={() => {
                    setchangeEmailLoading(false)
                    changeEmailForm.resetFields()
                }}>

                <h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Username/E-mail</h2>

                <Form
                    layout="vertical"
                    style={{ padding: "0px 20px 0px 20px" }}
                    width={200}
                    requiredMark={"optional"}
                    form={changeEmailForm}
                    onFinish={onChangeEmail}
                    initialValues={{
                        currentEmail: staffInfo?.username
                    }}
                >
                    <Form.Item
                        label="Current Username/E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="currentEmail"
                        rules={[
                            {
                                required: true,
                                message: "Please input your current e-mail",
                            },
                        ]}
                    >
                        <Input disabled={true} type={"email"} className="form-input-field" />
                    </Form.Item>

                    <Form.Item
                        label="New Username/E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="newEmail"
                        rules={[
                            { required: true, message: "Please input your new e-mail" },
                            { max: 50, message: 'Username/E-mail must be maximum 50 characters.' }]}
                    >
                        <Input type={"email"} className="form-input-field" />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Username/New E-mail"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="confirmNewEmail"
                        rules={[
                            { required: true, message: "Please confirm your new e-mail" },
                            { max: 50, message: 'Username/E-mail must be maximum 50 characters.' }
                        ]}
                    >
                        <Input type={"email"} className="form-input-field" />
                    </Form.Item>

                    <Form.Item>
                        <Button className="add-new-user-button" type="primary" style={{ width: "100%" }} htmlType="submit" loading={changeEmailLoading}>
                            Change E-mail
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>

            {/* change phone number modal */}
            <Modal
                width={450}
                visible={showChangeMobileNoModal}
                footer={null}
                onCancel={() => { setShowChangeMobileNoModal(false) }}
                destroyOnClose
                afterClose={() => changeMobileNoForm.resetFields()}>

                <h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Phone Number</h2>

                <Form
                    layout="vertical"
                    style={{ padding: "0px 20px 0px 20px" }}
                    width={200}
                    requiredMark={"optional"}
                    form={changeMobileNoForm}
                    onFinish={onChangeMobileNo}
                >
                    <Form.Item
                        label="New phone number"
                        style={{ color: "grey", fontStyle: "italic" }}
                        name="newPhoneNo"
                        rules={[
                            {
                                required: true,
                                pattern: /^(01)[0-46-9][0-9]{7,8}$/,
                                message: "Please enter a valid phone number",
                            },
                        ]}
                    >
                        <Input type={"tel"} className="form-input-field" />
                    </Form.Item>

                    <Form.Item>
                        <Button className="add-new-user-button" type="primary" style={{ width: "100%" }} htmlType="submit" loading={isLoading}>
                            Change phone number
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>
            {/* change password modal */}
            <Modal
                visible={showChangePasswordModal}
                destroyOnClose
                afterClose={() => {
                    changePasswordForm.resetFields();
                    setPasswordStrength(passwordStrengthMap["WEAK"]);
                    setShowPasswordStrength(false);
                }}
                footer={null}
                onCancel={() => setShowChangePasswordModal(false)}
            >
                <h2 style={{ fontWeight: "bold", textAlign: "center", marginTop: 30 }}>Change Password</h2>
                <Form
                    form={changePasswordForm}
                    onFinish={handleChangePassword}
                    layout="vertical"
                    style={{ padding: "0px 20px 0px 20px" }}
                    width={200}
                    requiredMark={"optional"}
                >
                    <Form.Item
                        label="New Password"
                        name="newPassword"
                        style={{ color: "grey", fontStyle: "italic", marginBottom: 5 }}
                        rules={[
                            {
                                required: true,
                                message: "Please input your new password",
                            },
                            {
                                max: 12,
                                min: 8,
                                message: "Password must have 8 - 12 characters",
                            },
                            {
                                pattern: /^(?=.*[A-Z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
                                message: "New password must have at least one capital letter",
                            },
                            {
                                pattern: /^(?=.*[a-z])[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
                                message: "New password must have at least one alphanumerical character",
                            },
                            {
                                pattern: /^(?=.*\d)[A-Za-z\d!@#$%^&*()_+|~`]{1,}$/,
                                message: "New password must have at least a number",
                            },
                        ]}
                    >
                        <Input.Password
                            type={"password"}
                            className="form-input-field"
                            visibilityToggle
                            onChange={() => checkPasswordStrength()}
                        />
                    </Form.Item>
                    {PasswordStrengthComponent()}
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        style={{ color: "grey", fontStyle: "italic" }}
                        rules={[
                            {
                                required: true,
                                message: "Please re-type your new password",
                            },
                        ]}
                    >
                        <Input.Password type={"password"} className="form-input-field" visibilityToggle />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className="add-new-user-button"
                            type="primary"
                            style={{ width: "100%" }}
                            htmlType="submit"
                            loading={changePasswordLoading}
                        >
                            Change password
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

const styles = {
    label: {
        font: 'italic normal normal 16px Open Sans',
        color: '#333333',
    },
    value: {
        color: '#333333',
        font: 'normal normal 600 16px Open Sans'
    },

    disclaimer: {
        color: 'grey', fontStyle: 'italic', marginBottom: '15px', width: "60%"
    }
}

export default AuthorizeUser