import { LoadingOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Alert, message, Row, Col, Space, Card, Image, Tooltip, Button, Skeleton,
	Modal, 
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SessionContext } from "../../App";
import i2i_image from "../../assets/icon-i2i.svg";
import in_image from "../../assets/icon-in.svg";
import out_image from "../../assets/icon-out.svg";
import reporting_image from "../../assets/icon-report.svg";
import user_management from "../../assets/icon-user-management.svg";
import branch_management from "../../assets/icon-branch-management.svg";
import inter_branch_management from "../../assets/inter-branch transfer.svg";
import user_guide from "../../assets/icon-user-guide.svg";
import renew_road_tax from "../../assets/illus-jpj-renew-road-tax-button@2x.png";
import renew_insurance from "../../assets/insurance.png";
import vehicle_transfer from "../../assets/illus-vehicle-transfer.png";
import info_icon from "../../assets/icon/Icon-info-24.svg";
import { OTHER_TRANSACTIONS_LINK, WALLET_MINIMUM_BALANCE } from "../../constants";
import "./index.less";
import { getUserRole, getAuthorizedNric, setAnnouncementShown, getAnnouncementShown } from "../../services/local";
import { getMysikapLink, getAnnouncement } from "../../services/api";
import { config } from "../../config";

export default function DashboardPage() {

	const { prepaid, branch, company } = useContext(SessionContext);
	const history = useHistory();

	const [isPrepaidLoading, setIsPrepaidLoading] = useState(false);
	const [isBranchUserLoading, setIsBranchUserLoading] = useState(false);
	const [role, setRole] = useState(null);
	const [mySikapLink, setMySikapLink] = useState("");
	const [isLowBalance, setisLowBalance] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [announcementModal, setAnnouncementModal] = useState([]);
	const [announcementAlert, setAnnouncementAlert] = useState([]);
	const [announcementTooltip, setAnnouncementTooltip] = useState([]);

	useEffect(async () => {

		if (company.id) {

			await getUserRole().then(async (role) => {
				setRole(role);
			});
			{/* commented to not show mysikap label */}
			/* await getMysikapLink(company.id).then(async (link) => {
				setMySikapLink(link.url);
			}); */
		}
	}, [company]);

	useEffect(() => {
		if (prepaid) {
			if (prepaid.balanceAmount < WALLET_MINIMUM_BALANCE) {
				setisLowBalance(true)
			}
		}
	}, [prepaid])

	function getBanner() {
		/**prepaid banner */
		if (isLowBalance) {
			return (
				<Alert
					closable
					onClose={() => setisLowBalance(false)}
					showIcon={false}
					className={"warning-alert"}
					message={
						<p style={{ color: "white" }}>
							Your balance is running low. Kindly top up your prepaid account balance to avoid payment
							interruption. &nbsp;
							<a
								href={config.reloadUrl}
								target={"_blank"}
								rel="noreferrer"
								style={{
									color: "white",
									textDecoration: "underline",
								}}
							>
								Reload account
							</a>
						</p>
					}
					banner
				/>
			);
		}
	}

	useEffect(() => {
		// Check if the 'announcementShown' flag is set in the local storage
		if (!getAnnouncementShown()) {
		  // Show the modal
		  setIsModalVisible(true);
		}

		getAnnouncement().then((res) => {
			res.map(item => {
				if(item.componentType === "MODAL") {
					setAnnouncementModal(item)
				}
				else if(item.componentType === "ALERT") {
					setAnnouncementAlert(item)
				}
				else if(item.componentType === "TOOLTIP") {
					setAnnouncementTooltip(item)
				}
			})
		}).catch((err) => {
			err && message.error(err?.toString())
		}).finally(() => {
		})
	}, []);
	
	const handleOk = () => {
		// Set the 'announcementShown' flag in local storage
		setAnnouncementShown(true)
		setIsModalVisible(false);
	};

	function getNotificationBanner() {
		/**prepaid banner */
		// if (isLowBalance) {
		/* return (
			<Alert
				// closable
				// onClose={() => setisLowBalance(false)}
				showIcon={false}
				className={"default-alert"}
				message={
					<p style={{ color: "black", marginLeft: '30px' }}>
						<b>
							All ownership transfer transactions must be completed with payment within a period of 7 days (including Saturdays and Sundays) - In accordance with the Transport Act.
							<br />
							<br />
							Semua transaksi tukar milik mesti diselesaikan dengan bayaran dalam tempoh 7 hari (termasuk hari Sabtu dan Ahad) - Mengikut Akta Pengangkutan.
						</b>
					</p>
				}
				banner
			/>
		); */
		// }
		return (
			<>
			{announcementAlert.length !== 0 && !announcementAlert.remove &&
				<Alert
					// closable
					// onClose={() => setisLowBalance(false)}
					showIcon={false}
					className={"default-alert"}
					message={<p dangerouslySetInnerHTML={{ __html: announcementAlert.message }}></p>}
					banner
				/>
			}
			{announcementModal.length !== 0 && !announcementModal.remove &&
				<Modal
					className="ant-modal-header-blue ant-modal-header-footer-padding"
					title={<b style={{color: "white"}}>ANNOUNCEMENT</b>}
					visible={isModalVisible}
					onOk={handleOk}
					onCancel={handleOk}
					centered // This prop will center the modal
					width="50%"
					style={{ minWidth: '500px' }}
					cancelButtonProps={{ style: { display: 'none' } }} // This will hide the cancel button
				>
					{<p dangerouslySetInnerHTML={{ __html: announcementModal.message }}></p>}
				</Modal> 
			}
			{/* <Alert
				// closable
				// onClose={() => setisLowBalance(false)}
				showIcon={false}
				className={"default-alert"}
				message={
					<p style={{ color: "black", marginLeft: '30px' }}>
						<b>
							All ownership transfer transactions must be completed with payment within a period of 7 days (including Saturdays and Sundays) - In accordance with the Transport Act.
							<br />
							<br />
							Semua transaksi tukar milik mesti diselesaikan dengan bayaran dalam tempoh 7 hari (termasuk hari Sabtu dan Ahad) - Mengikut Akta Pengangkutan.
						</b>
					</p>
				}
				banner
			/> */}
			  {/* <Modal
  				className="ant-modal-header-blue"
				title={<b style={{color: "white"}}>ANNOUNCEMENT</b>}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleOk}
				centered // This prop will center the modal
				width="50%"
				style={{ minWidth: '500px' }}
				cancelButtonProps={{ style: { display: 'none' } }} // This will hide the cancel button
			  >
				<p style={{ color: "black", marginLeft: '30px' }}>
					Currently, the system is experiencing errors <span style={{ color: "red"}}><b>VEL000624E & VEL000231E</b></span> - JPJ Verification Failed due to the road tax having expired for more than a year. The JPJ will take until <span style={{ color: "red"}}><b>July 19, 2024</b></span>, to resolve this issue unless you renew the road tax. We apologize for the inconvenience.<br /><br />
					Pada masa ini sistem mengalami ralat <span style={{ color: "red"}}><b>VEL000624E & VEL000231E</b></span>  - JPJ Verification Failed disebabkan cukai jalan telah luput melebihi setahun. Pihak JPJ akan mengambil masa sehingga <span style={{ color: "red"}}><b>19 Julai 2024</b></span> untuk menyelesaikan masalah ini kecuali anda memperbaharui cukai jalan. Kami memohon maaf atas kesulitan ini.
				</p>
			  </Modal> */}
					{/* <b>
						All ownership transfer transactions must be completed with payment within a period of 7 days (including Saturdays and Sundays) - In accordance with the Transport Act.
						<br />
						<br />
						Semua transaksi tukar milik mesti diselesaikan dengan bayaran dalam tempoh 7 hari (termasuk hari Sabtu dan Ahad) - Mengikut Akta Pengangkutan.
						<br />
						<br />
					</b>
					<br />
					Please take note that from 24th June 2024, our fees will be revised<br />
					Thank you for your cooperation and support during this transition.
					<br />
					<br />
					Harap maklum bahawa mulai 24 Jun 2024, harga kami akan diperbaharui<br />
					Terima kasih atas kerjasama dan sokongan anda sepanjang peralihan ini.<br /><br /><br />

					Temporarily, we are facing issues with Vehicle Permanent under UCD name not being able to proceed with Member ( UCD ) to Member ( UCD ) transactions via the system but we are sorting it out with JPJ as soon as possible. <br />
					However, Vehicle Temporary under UCD name (less than 6 months) can proceed with Member ( UCD ) to Member ( UCD ) transactions using system.<br /><br />

					Buat sementara waktu, kami menghadapi masalah Kenderaan Milik Kekal di bawah nama UCD tidak dapat meneruskan transaksi Ahli ( UCD ) kepada Ahli ( UCD ) melalui sistem tetapi kami sedang menyelesaikannya dengan JPJ secepat mungkin.<br />
					Walau bagaimanapun, Kenderaan Milik Sementara di bawah nama UCD (kurang daripada 6 bulan) boleh meneruskan transaksi Ahli ( UCD ) kepada Ahli ( UCD ) melalui sistem.<br /><br /><br />
 */}
					{/* Currently, the system is experiencing errors <span style={{ color: "red"}}><b>VEL000624E & VEL000231E</b></span> - JPJ Verification Failed due to the road tax having expired for more than a year. The JPJ will take until <span style={{ color: "red"}}><b>July 19, 2024</b></span>, to resolve this issue unless you renew the road tax. We apologize for the inconvenience.<br /><br />
					Pada masa ini sistem mengalami ralat <span style={{ color: "red"}}><b>VEL000624E & VEL000231E</b></span>  - JPJ Verification Failed disebabkan cukai jalan telah luput melebihi setahun. Pihak JPJ akan mengambil masa sehingga <span style={{ color: "red"}}><b>19 Julai 2024</b></span> untuk menyelesaikan masalah ini kecuali anda memperbaharui cukai jalan. Kami memohon maaf atas kesulitan ini. */}
					{/* Temporarily, we are facing an issue with <span style={{ color: "red"}}><b>vehicles permanently under the UCD name</b></span> not being able to proceed with <span style={{ color: "red"}}><b>Member to Member (UCD to UCD)</b></span> transactions via the system.<br />
					However, we are sorting this out with JPJ as soon as possible.<br />
					In the meantime, <span style={{ color: "red"}}><b>vehicles temporarily under the UCD name (less than 6 months)</b></span> can proceed with <span style={{ color: "red"}}><b>Member to Member (UCD to UCD)</b></span> transactions using the system. Once again, we apologize for the inconvenience caused and thank you for your understanding.<br /><br />

					Buat sementara waktu, kami menghadapi masalah dengan <span style={{ color: "red"}}><b>kenderaan yang secara kekal berada di bawah nama UCD</b></span> tidak dapat meneruskan transaksi <span style={{ color: "red"}}><b>Ahli ke Ahli (UCD ke UCD)</b></span> melalui sistem.<br />
					Namun, kami sedang menyelesaikan perkara ini dengan JPJ secepat mungkin.<br />
					Sementara itu, <span style={{ color: "red"}}><b>kenderaan yang secara sementara di bawah nama UCD (kurang dari 6 bulan)</b></span> boleh meneruskan transaksi <span style={{ color: "red"}}><b>Ahli ke Ahli (UCD ke UCD)</b></span> menggunakan sistem.<br />
					Sekali lagi, kami memohon maaf atas kesulitan yang berlaku dan terima kasih atas pemahaman anda.
				</p>
			  </Modal> */}
			</>
		  );
	}

	function loadingScreen() {
		if (!company.id) {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "25vh",
					}}
				>
					<LoadingOutlined style={{ fontSize: 50 }} />
				</div>
			);
		}
	}

	return (
		<>
			{getBanner()}
			<Row justify="space-between" style={{ padding: "32px 80px" }}>
				<Col className="dashboard-header">
					<h1>
						{company.coName}{" "}
						<span
							style={{
							fontFamily:
								"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
							}}
						>
							{company.ucdCode ? "(" + company.ucdCode + ")" : ""}
						</span>
					</h1>
					<h2>{branch.branchName}</h2>
				</Col>
				{role !== "VALUER" ? (
					<Col className="dashboard-header">
						<Space size={2}>
							{process.env.REACT_APP_ENVIRONMENT == "uat" ? <></> :
							<div>
								<h3
									style={{
										color: "white",
										opacity: 0.8,
										marginBottom: 0,
										textDecoration: "italic",
										flex: 2,
									}}
								>
									Remaining balance: &nbsp;
								</h3>

								<Tooltip
									title="Low balance"
									placement="bottomLeft"
									visible={isLowBalance}
									color={"#FF3939"}
								>
									<p style={{ color: "white", marginBottom: 0, flex: 2 }}>
										{" "}
										{isBranchUserLoading || isPrepaidLoading ? (
											<LoadingOutlined />
										) : prepaid?.balanceAmount >= 0 ? (
											`${new Intl.NumberFormat("en-US", {
												style: "currency",
												currency: "MYR",
											}).format(prepaid.balanceAmount)}`
										) : (
											`unavailable`
										)}
									</p>
								</Tooltip>
							</div>
							}
							<Button
								target={"_blank"}
								href={config.reloadUrl}
								id={"reload-button"}
								style={{
									flex: 2,
									marginLeft: 5,
									height: 45,
									paddingBottom: 0,
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								icon={<PlusCircleFilled />}
							>
								Reload
							</Button>
						</Space>
					</Col>
				) : null}
			</Row>

			<div style={{ background: "#F1F1F1", width: "100vw" }}>
			{getNotificationBanner()}
			{loadingScreen()}
				<Row
					style={{
						height: "100%",
						padding: "32px 100px 0px 100px",
						display: "flex",
						justifyContent: "space-between",
					}}
					gutter={[50, 30]}
				>

					{
						// role && role === 'SALES_MANAGER' &&
						role && role === 'MYEG_ADMIN' &&
						<Row gutter={[50, 30]} style={{ marginRight: 25 }}>
							<Col>
								<Space direction="vertical">
									<h1 className="dashboard-column-header">Account Management</h1>
									<Card
										className="dashboard-card-style"
										style={{ width: "380px", height: '70px' }}
										onClick={() => history.push("/adminCompany")}
									>
										<Row align="space-between">
											<h1 style={{ padding: 20 }}>Manage Company Account</h1>
										</Row>
									</Card>
									<Card
										className="dashboard-card-style"
										style={{ width: "380px", height: '70px' }}
										onClick={() => history.push("/adminSearchVehicleInfo")}
									>
										<Row align="space-between">
											<h1 style={{ padding: 20 }}>Search Vehicle Information</h1>
										</Row>
									</Card>
								</Space>
							</Col>
						</Row>
					}
					{
						// role && role !== 'SALES_MANAGER' &&
						role && role !== 'MYEG_ADMIN' &&
						<Row gutter={[50, 30]} style={{ marginRight: 25 }}>
							<Col>
								<Space direction="vertical">
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<h1 className="dashboard-column-header">Transfer Vehicle Ownership&nbsp;</h1>
										{announcementTooltip.length !== 0 && !announcementTooltip.remove &&
											<Tooltip overlayInnerStyle={{ color: 'black', marginBottom: "10px", width: "225%" }} color="white" placement="right"
												title={<div dangerouslySetInnerHTML={{ __html: announcementTooltip.message }} />}>
												<Image style={{marginBottom: "10px"}} src={info_icon} preview={false}/>
											</Tooltip>
										}
									</div>
									{
										role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' &&

										<Card
											className="dashboard-card-style"
											style={{ width: "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {

													history.push("/transfer/in/create", {
														step: 'OWNERSHIP_TYPE',
														subStep: 'OWNERSHIP_TYPE'
													})

												} else {
													history.push("/transfer/in/create", {
														step: 'AUTHORIZE_DEALER',
														subStep: 'D_INSERT_IC',
													})
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1>Owner to Dealer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>(STMS)</p>
												</Col>
												<Image width={135} height={70} src={in_image} preview={false} />
											</Row>
										</Card>
									}

									{/* {role !== "SALES" && role !== "VALUER" && role !== "ADMIN" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? ( */}
									{role !== "VALUER" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? (
										<Card
											// hoverable={false}
											// className="dashboard-card-style-disable"
											className="dashboard-card-style"
											style={{ width: "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {

													history.push("/transfer/out/create", {
														step: 'TRANSACTION_TYPE',
														subStep: 'TRANSACTION_TYPE'
													})

												} else {
													history.push("/transfer/out/create", {
														step: 'AUTHORIZE_DEALER',
														subStep: 'D_INSERT_IC',
													})
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1 style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>Dealer to Buyer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>
														(e-Serahan, Interstate)
													</p>
												</Col>
												<Image width={135} height={70} src={out_image} preview={false} />
											</Row>
										</Card>
									) : null}

									{role !== "VALUER" ? (
										<Card
											className="dashboard-card-style"
											style={{ width: "380px" }}
											onClick={() => {
												if (getAuthorizedNric()) {

													history.push("/transfer/i2i/create", {
														step: 'TRANSACTION_TYPE',
														subStep: 'TRANSACTION_TYPE'
													})

												} else {
													history.push("/transfer/i2i/create", {
														step: 'AUTHORIZE_DEALER',
														subStep: 'D_INSERT_IC',
													})
												}
											}}
										>
											<Row align="space-between">
												<Col style={{paddingTop:"16px"}}>
													<h1>Owner to Buyer</h1>
													<p style={{textAlign: "left", color: "white", paddingLeft: "16px"}}>
														(STMSR)
													</p>
												</Col>
												<Image width={135} height={70} src={i2i_image} preview={false} />
											</Row>
										</Card>
									) : null}
								</Space>
							</Col>
							<Col>
								<Space direction="vertical">
									<h1 className="dashboard-column-header">Other Transactions</h1>
									{/* commented to not show mysikap label */}
									{/* {mySikapLink ? (
										<Card
											className="dashboard-card-style"
											style={{ width: "300px" }}
											onClick={() => {
												window.open(mySikapLink, "_blank");
											}}
										>
											<Row align="space-between">
												<div style={{ alignSelf: "center", textAlign: "justify" }}>
													<h2>mySikap</h2>
													<h1>Permanent</h1>
													<h1>Vehicle Transfer</h1>
												</div>
												<Image width={150} height={70} src={vehicle_transfer} preview={false} />
											</Row>
										</Card>
									) : null} */}
									<Card
										className="dashboard-card-style"
										style={{ width: "300px" }}
										onClick={() => window.open(OTHER_TRANSACTIONS_LINK.INSURANCE, "_blank")}
									>
										<Row align="space-between">
											<div style={{ alignSelf: "center", textAlign: "justify" }}>
												<h2>Renew</h2>
												<h1>Insurance</h1>
											</div>
											<Image width={150} height={70} src={renew_insurance} preview={false} />
										</Row>
									</Card>
									<Card
										className="dashboard-card-style"
										style={{ width: "300px" }}
										onClick={() => window.open(OTHER_TRANSACTIONS_LINK.ROADTAX, "_blank")}
									>
										<Row align="space-between">
											<div style={{ alignSelf: "center", textAlign: "justify" }}>
												<h2>Renew</h2>
												<h1>Road Tax</h1>
											</div>
											<Image width={150} height={70} src={renew_road_tax} preview={false} />
										</Row>
									</Card>
								</Space>
							</Col>
						</Row>

					}

					{
						// role && role !== 'SALES_MANAGER' &&
						role && role !== 'MYEG_ADMIN' &&
						<Col style={{ paddingLeft: 0 }} span={6}>
							<Space direction="vertical" className="dashboard-system-service">
								<h1 className="dashboard-column-header">System Services</h1>
								<Space onClick={() => history.push("/reporting")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Reporting</h3>
								</Space>
								{role !== "ADMIN" && role !== "VALUER" && role !== "SALES" && role !== 'MYEG_RUNNER_1' && role !== 'MYEG_RUNNER_2' ? (
									<Space onClick={() => history.push("/usermanagement")} style={{ cursor: "pointer" }}>
										<Image src={user_management} width={40} preview={false} />
										<h3>User Management</h3>
									</Space>
								) : null}

								{role == "OWNER" || role == "GENERAL_MANAGER" ? (
									<Space onClick={() => history.push("/branchmanagement")} style={{ cursor: "pointer" }}>
										<Image src={branch_management} width={40} preview={false} />
										<h3>Branch Management</h3>
									</Space>
								) : null}

								{role !== "SALES" && role !== "VALUER" && role !== 'MYEG_OWNER_RUNNER' && role !== 'MYEG_RUNNER_2' && role !== 'MYEG_RUNNER_1' ? (
									<Space
										onClick={() => history.push("/interbranchtransfer")}
										style={{ cursor: "pointer" }}
									>
										<Image src={inter_branch_management} width={40} preview={false} />
										<h3>Inter-Branch Transfer</h3>
									</Space>
								) : null}
								<Space onClick={() => history.push("/userguide")} style={{ cursor: "pointer" }}>
									<Image src={user_guide} width={40} preview={false} />
									<h3>User Guide</h3>
								</Space>
								<Space onClick={() => history.push("/download")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Download</h3>
								</Space>
							</Space>
						</Col>
					}

					{
						// role && role === 'SALES_MANAGER' &&
						role && role === 'MYEG_ADMIN' &&
						<Col style={{ paddingLeft: 0 }} span={6}>
							<Space direction="vertical" className="dashboard-system-service">
								<h1 className="dashboard-column-header">System Services</h1>
								{/* <Space onClick={() => history.push("/reporting")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Reporting</h3>
								</Space> */}
								<Space onClick={() => history.push("/userguide")} style={{ cursor: "pointer" }}>
									<Image src={user_guide} width={40} preview={false} />
									<h3>User Guide</h3>
								</Space>
								<Space onClick={() => history.push("/download")} style={{ cursor: "pointer" }}>
									<Image src={reporting_image} width={40} preview={false} />
									<h3>Download</h3>
								</Space>
							</Space>
						</Col>
					}
				</Row>
			</div>
		</>
	);
}
