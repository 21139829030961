import React from "react";
import { Card, Image, Row, Col } from "antd";
import successIcon from "../../../../assets/icon-success.svg";
import failedIcon from "../../../../assets/icon-failed.svg";

const styles = {
  label: {
    font: "italic normal normal 16px Open Sans",
    color: "#333333",

    marginBottom: 0,
  },
  value: {
    font: "normal normal 600 16px Open Sans",
    color: "#333333",
  },
};

export const CompanyCardComponent = (sellerCompany, buyerCompany) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 10,
      }}
    >
      {
        <Card
          style={{
            flex: 3,
            textAlign: "left",
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: 4,
          }}
        >
          <>
            <h2
              style={{
                font: "normal normal 600 24px Raleway",
                marginBottom: 24,
              }}
            >
              Dealer
            </h2>
            <p style={styles.value}>
              <b>{sellerCompany.coName ? sellerCompany.coName : "-"}</b>(
              {sellerCompany.coRegNo ? sellerCompany.coRegNo : "-"})
            </p>
            <p style={styles.value}>
              {sellerCompany.contactNo ? sellerCompany.contactNo : "-"}
            </p>
            <p style={styles.value}>
              {sellerCompany.email ? sellerCompany.email : "-"}
            </p>
          </>
        </Card>
      }

      <Card
        style={{
          flex: 3,
          textAlign: "left",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <h2
          style={{ font: "normal normal 600 24px Raleway", marginBottom: 24 }}
        >
          Buyer
        </h2>
        <p style={styles.value}>
          <b>{buyerCompany.coName ? buyerCompany.coName : "-"}</b>(
          {buyerCompany.coRegNo ? buyerCompany.coRegNo : "-"})
        </p>
        <p style={styles.value}>
          {buyerCompany.contactNo ? buyerCompany.contactNo : "-"}
        </p>
        <p style={styles.value}>
          {buyerCompany.email ? buyerCompany.email : "-"}
        </p>
      </Card>
    </div>
  );
};

export const VerificationCard = (isSuccess) => {
  return (
    <div style={{ width: "100%" }}>
      <Card
        style={{
          height: "50%",
          textAlign: "left",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          // marginBottom: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Image
            src={isSuccess ? successIcon : failedIcon}
            alt="Success icon"
            preview={false}
            style={{ paddingRight: "20px" }}
          />
          <div style={{ display: "flex", flex: 4, alignItems: "center" }}>
            {isSuccess ? (
              <h2
                style={{
                  color: "#40e327a8",
                  font: "normal normal bold 23px/31px Open Sans",
                }}
              >
                JPJ checking completed
              </h2>
            ) : (
              <h2
                style={{
                  color: "#E53B34",
                  font: "normal normal bold 23px/31px Open Sans",
                }}
              >
                JPJ verification failed
              </h2>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export const TransactionAmountCard = (dataSource, totalVehicle) => {
  return (
    <div style={{ width: "100%" }}>
      <Card
        className="custom-transaction-card"
        bodyStyle={{ padding: 0, textAlign: "justify" }}
        style={{
          height: "50%",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "4px",
          // marginBottom: 16,
        }}
        bordered={false}
      >
        {/* First Row */}
        <Row justify="center" style={{ paddingTop: "24px" }}>
          <Col>
            <h3 style={{ textAlign: "center" }}>Ownership transfer for</h3>
            <h2 style={{ textAlign: "center" }}>
              <b>{totalVehicle} vehicles</b>
            </h2>
            <br />
          </Col>
        </Row>

        {/* Second Row */}
        <Row justify="space-between">
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <span>&nbsp;Item Description</span>
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <span>Amount (RM)</span>
          </Col>
        </Row>
        {/* Third Row */}
        <Row style={{ backgroundColor: "#f0f0f0", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <b>Processing Fee</b>
            {/* <br />
            <span>RM10 x {totalVehicle} vehicles</span> */}
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.processingAmount
                ? dataSource.processingAmount.toFixed(2)
                : "-"}
            </b>
            <br />
          </Col>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            <b>MYEG Service Charge</b>
            {/* <br />
            <span>RM2.75 x {totalVehicle} vehicles</span> */}
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.eserviceAmount
                ? dataSource.eserviceAmount.toFixed(2)
                : "-"}
            </b>
          </Col>
        </Row>

        {/* Fourth Row */}
        <Row style={{ backgroundColor: "#a9a9a9", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            Subtotal
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.subTotalAmount
                ? dataSource.subTotalAmount.toFixed(2)
                : "-"}
            </b>
          </Col>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            SST @ 8%
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.sstAmount ? dataSource.sstAmount.toFixed(2) : "-"}
            </b>
          </Col>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            JPJ Ownership Transfer Fee
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <b>
              {dataSource.jpjAmount ? dataSource.jpjAmount.toFixed(2) : "-"}
            </b>
          </Col>
        </Row>

        {/* Last Row */}
        <Row style={{ backgroundColor: "#4b0082", color: "white", padding: 8 }}>
          <Col span={12} style={{ paddingLeft: "20%", textAlign: "left" }}>
            Total amount
          </Col>
          <Col span={12} style={{ paddingRight: "20%", textAlign: "right" }}>
            <span style={{ fontSize: "large" }}>
              RM{" "}
              {dataSource.totalAmount ? dataSource.totalAmount.toFixed(2) : "-"}
            </span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

<style>
  {`
      .custom-transaction-card {
        text-align: justify !important;
      }
      .ant-card .custom-transaction-card {
        text-align: justify !important;
      }
  `}
</style>;
