import {
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Card, message, Row, Pagination } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PropsFromChildren } from ".";
import { SessionContext } from "../../App";
import {
  HQ_BRANCH,
  APP_CONST,
  BUTTON_RETURN_PENDING_TRANSACTION,
  ALERT_AUTHORITY_MESSAGE,
  BUTTON_RESUBMIT,
} from "../../constants";
import {
  getInTransferByTransferId,
  getAdminUsersForOut,
  verifyApproverSmsOtp,
  verifyApproverOTP,
  getInBulkTransactionDetails,
  getInTransferAmountByTransferId,
} from "../../services/api";
import {
  GetInTransferDetailRequest,
  GetInTransferAmountRequest,
} from "../../services/api/types";
import {
  CompanyResponse,
  VehicleResponse,
  CompanyData,
  vehicleDetail,
  TransactionAmount,
} from "./types";
import { getAuthorizedNric } from "../../services/local";
import ApproverAuthModal from "./jpjSubmission_modal";
import { getDecryptedVerifyOtpResponse } from "../../util/index";
import {
  VerificationCard,
  CompanyCardComponent,
  TransactionAmountCard,
} from "../../components/component/Layout/CardComponent";
import { VehicleDetailsTableComponent } from "../../components/component/Layout/TableComponent";
import {
  ApprovalButton,
  TextButton,
  JPJSubmissionButton,
} from "../../components/component/UI/Button";
import { InfoAlert } from "../../components/component/UI/Alert";
import { saveBulkM2mProceed, saveM2mProceed } from "./function";

type JPJVerificationProps = {
  company: CompanyResponse;
  vehicle: VehicleResponse;
  transferId: number;
  isJpjSubmitted: boolean;
  isReport: boolean;
  isFromAdminPage: boolean;
  sellerInquirySuccess: boolean;
  bulkTransferId: number;
  next: (data?: PropsFromChildren) => void;
};

export default function JPJVerification(props: JPJVerificationProps) {
  const { company } = useContext(SessionContext);
  const history = useHistory();
  const [isJPJLoading, setIsJPJLoading] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(true);
  const [transferId, setTransferId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isIncorrectOtp, setIsIncorrectOtp] = useState(false);
  const [isDisable, setIsDisabled] = useState(false);
  const [isApprovalRejected, setIsApprovalRejected] = useState(false);
  const [bulkTransferId, setBulkTransferId] = useState(0);
  const [totalSuccess, setTotalSuccess] = useState(0);
  const [sellerCompanyData, setSellerCompanyData] = useState<CompanyData>({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [buyerCompanyData, setBuyerCompanyData] = useState<CompanyData>({
    id: 0,
    coName: "",
    coRegNo: "",
    email: "",
    contactNo: "",
    ucdCode: "",
  });
  const [vehicleDetails, setVehicleDetails] = useState<vehicleDetail[]>([]);
  const [transactionAmount, setTransactionAmount] = useState<TransactionAmount>(
    {
      id: 0,
      name: "",
      jpjAmount: 0,
      eserviceAmount: 0,
      fisAmount: 0,
      fisEserviceAmount: 0,
      sstAmount: 0,
      totalAmount: 0,
      processingAmount: 0,
      subTotalAmount: 0,
    }
  );

  const MODULE_TYPE = {
    SUBMISSION: "SUBMISSION",
    APPROVAL: "APPROVAL",
    ENTRY_APPROVAL: "ENTRY_APPROVAL",
  };

  const dataSource = vehicleDetails ? vehicleDetails : [];

  /* Pagination */
  const [isHeaderEditing, setIsHeaderEditing] = useState(false);
  const [isFooterEditing, setIsFooterEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPageSize = 10;
  const totalPages = Math.ceil(dataSource.length / maxPageSize);
  const startItem = (currentPage - 1) * maxPageSize + 1;
  const endItem = Math.min(currentPage * maxPageSize, dataSource.length);
  const paginatedData = dataSource.slice(
    (currentPage - 1) * maxPageSize,
    Math.min(currentPage * maxPageSize, dataSource.length)
  );
  const onPageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleInputChange = (e) => {
    setCurrentPage(e.target.value);
  };
  const handleInputBlur = () => {
    const page = Math.max(1, Math.min(totalPages, Number(currentPage)));
    onPageChange(page);
    setIsHeaderEditing(false);
    setIsFooterEditing(false);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  /* Pagination */

  /* getInBulkTransactionDetails */
  useEffect(() => {
    if (props.bulkTransferId !== 0) {
      setIsJPJLoading(true);
      getInBulkTransactionDetails(props.bulkTransferId, "BULKM2M").then(
        (res) => {
          try {
            console.log("detail", res);
            const sellerCompany: CompanyData = {
              id: 0,
              coName: res.seller.name,
              coRegNo: res.seller.identificationNo,
              email: res.seller.email ?? "",
              contactNo: res.seller.phoneNo ?? "",
              ucdCode: "",
            };
            setSellerCompanyData(sellerCompany);

            const buyerCompany: CompanyData = {
              id: 0,
              coName: res.buyer.name,
              coRegNo: res.buyer.identificationNo,
              email: res.buyer.email ?? "",
              contactNo: res.buyer.phoneNo ?? "",
              ucdCode: "",
            };
            setBuyerCompanyData(buyerCompany);

            const dataWithKey = res.vehicleDetailList.map((item, index) => {
              return {
                key: index + 1,
                ...item,
              };
            });
            setVehicleDetails(dataWithKey);
            setTransactionAmount(res.transactionAmount);
            setTotalSuccess(res.totalSuccess);
          } catch (err) {
          } finally {
          }
        }
      );

      setIsJPJLoading(false);
    }
  }, [props.bulkTransferId]);

  useEffect(() => {
    if (props.transferId !== 0) {
      getInTransactionDetail();
    }
  }, [props.transferId]);

  const getInTransactionDetail = () => {
    setIsJPJLoading(true);
    setTransferId(props.transferId);
    const transferId = props.transferId;
    const getInTransferDetailRequest: GetInTransferDetailRequest = {
      transferId,
      uId: "",
      companyId: 0,
      moduleType: "M2M",
    };
    getInTransferByTransferId(getInTransferDetailRequest).then((res) => {
      try {
        const sellerCompany: CompanyData = {
          id: 0,
          coName: res.sellerInfo.name,
          coRegNo: res.sellerInfo.identificationNo,
          email: res.sellerInfo.email,
          contactNo: res.sellerInfo.phoneNo,
          ucdCode: "",
        };
        setSellerCompanyData(sellerCompany);

        const buyerCompany: CompanyData = {
          id: 0,
          coName: res.buyerInfo.name,
          coRegNo: res.buyerInfo.identificationNo,
          email: res.buyerInfo.email,
          contactNo: res.buyerInfo.phoneNo,
          ucdCode: "",
        };

        setBuyerCompanyData(buyerCompany);

        const vehicle: vehicleDetail = {
          carRegistrationNo: res.carRegistrationNo,
          chassisNo: res.chassisNo,
          engineNo: res.engineNo,
          vehicleTypeNewId: res.vehicleTypeNewId,
          failCode: res.sellerInquirySuccess === null ? res.failCode : "",
        };
        const dataWithKey = {
          key: 1,
          ...vehicle,
        };
        setVehicleDetails([dataWithKey]);
        setTotalSuccess(1);

        if (res.sellerInquirySuccess === null) {
          setVerificationSuccess(false);
        } else {
          const getInTransferAmountRequest: GetInTransferAmountRequest = {
            transferId,
          };

          getInTransferAmountByTransferId(getInTransferAmountRequest)
            .then((res) => {
              if (res.id === undefined) throw res.message;

              const amount: TransactionAmount = {
                id: res.id,
                name: res.name,
                jpjAmount: res.jpjAmount,
                eserviceAmount: res.eserviceAmount,
                fisAmount: res.fisAmount,
                fisEserviceAmount: res.fisEserviceAmount,
                sstAmount: res.sstAmount,
                totalAmount: res.totalAmount,
                processingAmount: res.processingAmount,
                subTotalAmount: res.processingAmount + res.eserviceAmount,
              };
              setTransactionAmount(amount);
            })
            .catch((err) => err && message.error(err))
            .finally(() => {});
        }
      } catch (err) {
      } finally {
      }
    });

    setIsJPJLoading(false);
  };

  const LoadingCard = () => {
    return (
      <Card>
        <LoadingOutlined />
      </Card>
    );
  };

  const onApprovalSubmit = async () => {
    let isAdminUser = false;
    await getAdminUsersForOut(company.id, HQ_BRANCH.id)
      .then((res) => {
        if (res.status !== true) throw res.message;
        res.users.map((user) => {
          if (user.nric_no === getAuthorizedNric()) {
            isAdminUser = true;
            // message.success("APPROVED")
          }
        });
      })
      .catch((err) => err && message.error(err.toString()))
      .finally(async () => {
        console.log("isAdminUser", isAdminUser);
        if (!isAdminUser) {
          setIsModalVisible(true);
        } else {
          processApproval();
        }
      });
  };

  const onResubmit = async () => {
    const response = await saveM2mProceed(
      props.transferId,
      "SUBMISSION",
      onResetData
    );
    if (response.inquiryStatus === "00") {
      setVerificationSuccess(true);
    } else {
      setVerificationSuccess(false);
    }
    getInTransactionDetail();
  };

  const onResetData = () => {};

  const processApproval = useCallback(async () => {
    let status = false;
    if (props.bulkTransferId && props.bulkTransferId !== 0) {
      status = await saveBulkM2mProceed(
        setBulkTransferId,
        props.bulkTransferId,
        [],
        company.id,
        onResetData,
        MODULE_TYPE.ENTRY_APPROVAL
      );
    } else {
      const response = await saveM2mProceed(
        props.transferId,
        "APPROVAL",
        onResetData
      );
      status = response.inquiryStatus === "00";
    }

    if (status) {
      props.next({
        transferId: props.transferId,
        bulkTransferId: props.transferId,
      });
    }
  }, []);

  const approvalAuthModal = () => {
    return (
      <ApproverAuthModal
        isIncorrectOtp={isIncorrectOtp}
        isModalVisible={isModalVisible}
        bulkTransferId={props.bulkTransferId}
        isApprover={true}
        isM2M={true}
        transferId={transferId}
        onCancel={() => setIsModalVisible(false)}
        onOk={(approverIc, authCode, isPush, appApprovalResponse) => {
          if (isPush) {
            setIsModalVisible(false);
            if (appApprovalResponse.isRejected) {
              setIsApprovalRejected(true);
            } else {
              setIsApprovalRejected(false);
              // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
              processApproval();
            }
          } else if (APP_CONST.USE_OTP === true) {
            verifyApproverSmsOtp(approverIc, authCode)
              .then((res) => {
                if (!res.encryptedString) {
                  let errorMsg = res.message ? res.message : "ERROR";
                  throw errorMsg;
                }
                const decryptedResponse = getDecryptedVerifyOtpResponse(
                  res.encryptedString
                );
                if (decryptedResponse.status == "false") {
                  setIsIncorrectOtp(true);
                  throw decryptedResponse.message;
                }
                setIsIncorrectOtp(false);
                setIsModalVisible(false);
                processApproval();
                // Next step: JPJ Verification
                // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId})
              })
              .catch((err: any) => {
                setIsIncorrectOtp(true);
                err && message.error(err);
              })
              .finally(() => {
                setIsIncorrectOtp(false);
              });
          } else {
            verifyApproverOTP(approverIc, authCode)
              .then((res) => {
                if (res.status !== true) {
                  setIsIncorrectOtp(true);
                  throw res.message;
                }
                setIsIncorrectOtp(false);
                setIsModalVisible(false);
                processApproval();
                // Next step: JPJ Verification
                // props.next({ vehicleProps: vehicle, transferId: m2MBuyerResponse?.transferId })
              })
              .catch((err: any) => {
                setIsIncorrectOtp(true);
                err && message.error(err);
              })
              .finally(() => {
                setIsIncorrectOtp(false);
              });
          }
        }}
      />
    );
  };

  const onBackButton = () => {
    props.isFromAdminPage
      ? history.push("/adminSearchVehicleInfo")
      : props.isReport
      ? history.push("/reporting/transferreport/")
      : history.push("/transfer/out/create");
  };

  /* Pagination */
  const CustomPagination = ({
    currentPage,
    totalPages,
    onPageChange,
    isEditing,
    setIsEditing,
  }) => {
    return (
      <div className="custom-pagination">
        <Button
          type="text"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <LeftOutlined />
        </Button>
        {isEditing ? (
          <span>
            Page{" "}
            <input
              style={{ maxWidth: "40px" }}
              type="number"
              value={currentPage}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              autoFocus
            />{" "}
            of {totalPages}{" "}
          </span>
        ) : (
          <span onClick={() => setIsEditing(true)}>
            Page {currentPage} of {totalPages}
          </span>
        )}
        <Button
          type="text"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <RightOutlined />
        </Button>
      </div>
    );
  };

  const PaginationWrapper = (type) => {
    return (
      <>
        <Row className="custom-wrap-row">
          <span>
            Showing{" "}
            <b>
              {startItem} - {endItem}
            </b>{" "}
            of <b>{dataSource.length} vehicles</b>.&nbsp;&nbsp;&nbsp;&nbsp;All
            JPJ verification are saved in{" "}
            <Button style={{ padding: 0 }} type="link" onClick={onBackButton}>
              pending submission
            </Button>
          </span>
          {type === "header" ? (
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isEditing={isHeaderEditing}
              setIsEditing={setIsHeaderEditing}
            />
          ) : (
            <CustomPagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              isEditing={isFooterEditing}
              setIsEditing={setIsFooterEditing}
            />
          )}
        </Row>
      </>
    );
  };

  const VehicleDetailPaginationTable = () => {
    return (
      <Card
        style={{
          flex: 3,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: 4,
        }}
      >
        <>
          <Pagination
            current={currentPage}
            pageSize={1}
            total={totalPages}
            onChange={(page) => {
              setCurrentPage(page);
            }}
            showQuickJumper={false}
            showSizeChanger={false}
            style={{ marginBottom: "16px" }}
          />
          <PaginationWrapper type={"header"} />
          {VehicleDetailsTableComponent(paginatedData)}
          <PaginationWrapper type={"footer"} />
        </>
      </Card>
    );
  };
  /* Pagination */

  return (
    <>
      {isJPJLoading ? (
        <LoadingCard />
      ) : (
        <>
          <>19</>
          {VerificationCard(verificationSuccess)}
          <br />
          {VehicleDetailPaginationTable()}
          <br />
          {CompanyCardComponent(sellerCompanyData, buyerCompanyData)}
          <br />
          {transactionAmount.totalAmount !== 0 &&
            TransactionAmountCard(
              transactionAmount ? transactionAmount : [],
              totalSuccess
            )}
          <br />
          {/* {InfoAlert(ALERT_AUTHORITY_MESSAGE)} */}
          {TextButton(BUTTON_RETURN_PENDING_TRANSACTION, onBackButton)}
          {verificationSuccess
            ? ApprovalButton(onApprovalSubmit)
            : JPJSubmissionButton(onResubmit, isDisable, BUTTON_RESUBMIT)}
        </>
      )}
      {approvalAuthModal()}

      <style>
        {`
          .ant-pagination {
            display: none;
          }
          .custom-pagination {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          .custom-wrap-row {
            display: flex;
            justify-content: space-between;
          }
        `}
      </style>
    </>
  );
}
