import React from "react";
import {
  Form,
} from "antd";

export const TermAndConditionFormItem = (TermAndConditionCheckbox) => {
  return (
    <Form.Item
      name="tnc"
      valuePropName="checked"
      rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(),
          message: (
            <span>
              To proceed, you need to agree with the terms & conditions
            </span>
          ),
        },
      ]}
    >
      {TermAndConditionCheckbox()}
    </Form.Item>
  );
};
